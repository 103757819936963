import React from 'react';
// import { useForm } from "react-hook-form";

import CheckIcon from '../icons/CheckIcon';
import tw, { css } from 'twin.macro';

interface Props {
  checked: boolean;
  name: string;
  value: string;
  error?: string;
  label: string;
  className?: string;
  classNameLabel?: string;
  classNameInput?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void; //update to event
  disabled?: boolean;
}

// const ErrorMessage = tw.div`w-96 mt-2 font-light text-sm text-red-500`;
const RadioField = ({
  checked,
  error,
  name,
  value,
  label,
  className,
  classNameLabel,
  classNameInput,
  onChange,
  disabled = false,
}: Props) => {
  return (
    <div className={className || ''}>
      <input
        type='radio'
        id={label}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={classNameInput || ''}
        tw='focus:ring-0 focus:ring-offset-0'
        disabled={disabled}
      />
      <label
        htmlFor={label}
        tw='ml-2.5 text-sm font-light'
        className={classNameLabel || 'text-black-100'}
      >
        {label}
      </label>
      <br></br>
      {/* <label tw='mb-2 inline-flex items-center cursor-pointer relative' htmlFor={name}>
        <input
          tw='w-6 h-6 box-border appearance-none border-grey-light border rounded-md'
          type='checkbox'
          name={name}
          id={name}
          checked={checked}
          onChange={onChange}
        />

        {checked && (
          <div
            css={css`
              top: 0.35rem;
              left: 0.25rem;
              ${tw`absolute`}
            `}
          >
            <CheckIcon />
          </div>
        )}
        <span tw='ml-2.5 font-light text-sm	font-body text-black-100'>{label}</span>
      </label> */}
      {/* <div tw='mt-2 font-light text-sm text-red-500'>{error}</div> */}
      {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
    </div>
  );
};

export default RadioField;
