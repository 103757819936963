import React from 'react';

function EcontomatIcon() {
  return (
    <svg
      id='Pin_econtomat'
      data-name='Pin econtomat'
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='48'
      viewBox='0 0 32 48'
    >
      <g id='Group_23692' data-name='Group 23692'>
        <path
          id='dropshape'
          d='M32,15.412a16.857,16.857,0,0,1-1.746,7.549L17.011,47.406a1.147,1.147,0,0,1-2.01,0L1.574,22.619A17.145,17.145,0,0,1,0,15.412C0,6.739,7.373,0,16.009,0S32,6.735,32,15.412Z'
          fill='#003f85'
        />
        <g id='e_icon' data-name='e icon' transform='translate(10 8)'>
          <path
            id='e_icon-2'
            data-name='e icon'
            d='M1.823,16A1.9,1.9,0,0,1,.018,13.714L1.4,2.286A2.539,2.539,0,0,1,3.751,0h7.127a1.769,1.769,0,0,1,1.677,2.122A2.357,2.357,0,0,1,10.37,4.245H5.322L4.41,11.756H9.459a1.768,1.768,0,0,1,1.677,2.122A2.359,2.359,0,0,1,8.951,16ZM5.8,8.143a2,2,0,1,1,2,2A2,2,0,0,1,5.8,8.143Z'
            transform='translate(0)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}

export default EcontomatIcon;
