import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { css, styled } from 'twin.macro';
import Wrapper from '../components/layout/Wrapper';
import GrayBackgroundWrapper from '../components/layout/GrayBackgroundWrapper';
import WhiteBoxWrapper from '../components/layout/WhiteBoxWrapper';
import LogoIcon from '../components/icons/LogoIcon';
import RadioField from '../components/form/RadioField';
import OfficePaymentMobile from '../components/common/OfficePaymentMobile';
import BankTransfer from '../components/common/BankTransfer';
import useQuery from '../hooks/use-query-hook';
import { isMobile, isTablet } from 'react-device-detect';
import OfficePayment from '../components/common/OfficePayment';
import LogoIconWeb from '../components/icons/LogoIconWeb';
import EcointsLogo from '../components/icons/EcointsLogo';
import EcointsPayment from '../components/common/EcointsPayment';
import LeftArrow from '../components/icons/LeftArrow';
import Button from '../components/Button/Button';
import { getClaimByIdExternal, updateExternalClaim } from '../services/actions';
import AlertPopup from '../components/layout/AlertPopup';
import LoadingSpinner from '../components/common/LoadingSpinner';

import EcointsNotificationSent from '../components/features/EcointsNotificationSent';
import BankAccountDropdown from '../components/dropdowns/bank-account-dropdown';
import ModalMIssingEcointsNumber from '../components/modals/ModalMIssingEcointsNumber';
import ModalEcointsDIgitalWallet from '../components/modals/ModalEcointsDIgitalWallet';

const WhiteBoxWrapperCustom = styled.div(() => [
  css`
    background-color: white;
    background-image: repeating-linear-gradient(
        48deg,
        #deddda,
        #deddda 7px,
        transparent 7px,
        transparent 14px,
        #deddda 14px
      ),
      repeating-linear-gradient(
        138deg,
        #deddda,
        #deddda 7px,
        transparent 7px,
        transparent 14px,
        #deddda 14px
      ),
      repeating-linear-gradient(
        228deg,
        #deddda,
        #deddda 7px,
        transparent 7px,
        transparent 14px,
        #deddda 14px
      ),
      repeating-linear-gradient(
        318deg,
        #deddda,
        #deddda 7px,
        transparent 7px,
        transparent 14px,
        #deddda 14px
      );
    background-size: 8px 0, 0 8px, 8px 0, 100% 8px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
  `,
]);

const PayOnline = () => {
  const [isClaimDecisionIsPayThroughEcoints, setIsClaimDecisionIsPayThroughEcoints] =
    useState(false);
  const [isOpenEcointsModal, setIsOpenEcointsModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('in_office');
  const [isEcointsNotificationSent, setIsEcointsNotificationSent] = useState(false);
  const [isOpenModalMissingEcointsNumber, setIsOpenModalMissingEcointsNumber] = useState(false);

  // in_office
  const [hoveredBox, setHoveredBox] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [office, setOffice] = useState({
    id: false,
    name: '',
    code: '',
    address: {
      fullAddress: '',
    },
  });
  const [defaultOffice, setDefaultOffice] = useState({
    id: 1007,
    address: {
      fullAddress: 'Пловдив бул. Христо Ботев №7',
    },
  });
  const [isSelectedDefaultOffice, setIsSelectedDefaultOffice] = useState(false);
  // bank_type
  const [iban, setIban] = useState('');
  const [bic, setBic] = useState('');
  const [bank, setBank] = useState('');
  const [name, setName] = useState('');
  const [errorMessageIBAN, setErrorMessageIBAN] = useState('');
  const [errorMessageBIC, setErrorMessageBIC] = useState('');
  const [errorMessageBANK, setErrorMessageBANK] = useState('');
  const [errorMessageNAME, setErrorMessageNAME] = useState('');
  //ecointsType
  const [tel, setTel]: any = useState(undefined);
  const [errorTel, setErrorTel] = useState('');

  const params: any = useParams();
  const history = useHistory();
  const queryParameters = useQuery();

  const skipAuth = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2
  );

  const skipAuthFullParameters = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2 &&
    (queryParameters.skipNumbers ||
      queryParameters.awbNumbers ||
      queryParameters.moneyTransferNumbers) &&
    // (queryParameters.idClient || queryParameters.Clients) &&
    queryParameters.time
  );

  const [showGlobalError, setShowGlobalError] = useState(false);
  const [globalError, setGlobalError] = useState('');

  const [claim, setClaim] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [bankAccount, setBankAccount] = useState<any>();
  const [openIbanDropdown, setOpenIbanDropdown] = useState(false);

  const onSetBankAccountData = (bankAccounts: any) => {
    setIban(bankAccounts[0].IBAN);
    setBic(bankAccounts[0].BIC);
    setName(bankAccounts[0].owner);
    setBank(bankAccounts[0].bankName);
    setBankAccount(bankAccounts[0]);
  };

  useEffect(() => {
    setLoading(true);
    const { page, skipNumbers, ...restParams } = queryParameters;
    getClaimByIdExternal(params.id, restParams)
      .then((result) => {
        if (result) {
          if (
            result.offerCode === '0103' ||
            result.offerCode === '0104' ||
            result.offerCode === '0107'
          ) {
            setClaim(result);

            if (result.ecointsPhones && result.ecointsPhones.length) {
              if (result.ecointsPhones[0].startsWith('+')) {
                setTel(result.ecointsPhones[0]);
              } else {
                setTel('+' + result.ecointsPhones[0]);
              }
            }
            if (result?.bankAccounts?.length > 0) {
              onSetBankAccountData(result?.bankAccounts);
            }

            if (result.offerCode === '0107') {
              setPaymentMethod('ecoints');
              setIsClaimDecisionIsPayThroughEcoints(true);
            }
          } else {
            setShowGlobalError(true);
            setGlobalError('Няма предложено обезщетение по рекламацията');
          }
          setLoading(false);
        } else {
          //   setError('Failed to fetch claim');
        }
      })

      .catch((err) => {
        console.log(err, 'err');
        setLoading(false);
        // setError('Failed to fetch claim');
      });
  }, []);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { type, name, checked, value } = e.target;

    setPaymentMethod(value);
  };

  const isDisabledBankType = () => {
    if (
      iban &&
      bic &&
      bank &&
      name &&
      !errorMessageIBAN &&
      !errorMessageBIC &&
      !errorMessageBANK &&
      !errorMessageNAME
    )
      return false;
    else return true;
  };

  const isDisabledOfficeType = () => {
    return !isSelectedDefaultOffice && !office?.id ? true : false;
  };

  const isDisableEcointsType = () => {
    return !errorTel && tel ? false : true;
  };

  const clearErrorsEcointsType = () => {
    setErrorTel('');
  };

  const clearErrorsBankType = () => {
    setErrorMessageIBAN('');
    setErrorMessageBIC('');
    setErrorMessageBANK('');
    setErrorMessageNAME('');
  };
  const handleSubmitBankType = async () => {
    try {
      const res = await updateExternalClaim(
        {
          reclamationNum: params.id,
          clientAgreeWithDecision: true,
          paymentOptions: {
            type: 'bank',
            params: {
              IBAN: iban,
              BIC: bic,
              bankName: bank,
              bankAccountOwnerName: name,
            },
          },
        },
        queryParameters,
        skipAuth || skipAuthFullParameters
      );

      if (res && res.error) {
        switch (true) {
          case res.error.includes('IBAN'): {
            setErrorMessageIBAN('Моля, въведете валиден IBAN.');
            break;
          }
          case res.error.includes('BIC'): {
            setErrorMessageBIC('Моля, въведете валиден BIC.');
            break;
          }
          case res.error.includes('банка'): {
            setErrorMessageBANK('Невалидно име на банката. Минимален брой символи: 3');
            break;
          }
          case res.error.includes('име'): {
            setErrorMessageNAME(res.error);
            break;
          }
          default: {
            setShowGlobalError(true);
            setGlobalError(res.error);
            break;
          }
        }

        return;
      }

      handleRouteBack();
    } catch (err: any) {
      // if (err && err.response && err.response.data && err.response.data.message) {
      //   switch (err.response.data.message) {
      //     case 'Моля, въведете валиден IBAN.': {
      //       setErrorMessageIBAN('Моля, въведете валиден IBAN.');
      //       break;
      //     }
      //     case 'Моля, въведете валиден BIC.': {
      //       setErrorMessageBIC('Моля, въведете валиден BIC.');
      //       break;
      //     }
      //     case 'Невалидно име на банката. Минимален брой символи: 3': {
      //       setErrorMessageBANK('Невалидно име на банката. Минимален брой символи: 3');
      //       break;
      //     }
      //     case 'Невалидно име. Минимален брой символи: 3': {
      //       setErrorMessageNAME('Невалидно име. Минимален брой символи: 3');
      //       break;
      //     }
      //     default: {
      //       console.log('Cannot find such a case!');
      //       break;
      //     }
      //   }
      // }
      console.log('err', err.response);
    }
  };

  const handleSubmitOfficeType = async () => {
    const res = await updateExternalClaim(
      {
        reclamationNum: params.id,
        clientAgreeWithDecision: true,
        paymentOptions: {
          type: 'office',
          params: {
            officeId: office && office.id ? office.id.toString() : defaultOffice.id.toString(),
          },
        },
      },
      queryParameters,
      skipAuth || skipAuthFullParameters
    );

    if (res && !res.error) {
      handleRouteBack();
    } else {
      setShowGlobalError(true);
      setGlobalError(res?.error);
    }
  };

  const handleSubmitEcointsType = async () => {
    try {
      const res = await updateExternalClaim(
        {
          reclamationNum: params.id,
          clientAgreeWithDecision: true,
          paymentOptions: {
            type: 'ecoints',
            params: {
              phone: tel.toString(),
            },
          },
        },
        queryParameters,
        skipAuth || skipAuthFullParameters
      );

      if (res && res.error) {
        if (res.error.includes('не е регистриран')) {
          return setIsOpenModalMissingEcointsNumber(true);
        }
        setShowGlobalError(true);
        setGlobalError(res?.error?.message);
        return;
      }

      setIsEcointsNotificationSent(true);
    } catch (err: any) {
      console.log('err', err.response);
    }
  };

  const handleRouteBack = () => {
    history.goBack();
  };

  const handleSubmit = () => {};

  const onChooseBankAccount = (bankAccount: any) => {
    onSetBankAccountData([bankAccount]);
    setOpenIbanDropdown(false);
  };

  const renderBankAccountDropdown = () => {
    return (
      <BankAccountDropdown
        open={openIbanDropdown}
        setOpen={setOpenIbanDropdown}
        onChooseHandler={onChooseBankAccount}
        bankAccounts={claim?.bankAccounts}
        bankAccount={bankAccount}
      />
    );
  };

  const renderMobileApp = () => {
    return (
      <>
        <GrayBackgroundWrapper top='0rem' />
        <div className={`${localStorage.getItem('hideHeader') ? 'mt-2' : 'mt-16'}`}>
          <WhiteBoxWrapper className='p-4'>
            <div>
              <div tw='flex justify-between items-center'>
                <span tw='text-[13px] text-gray-400'>Документ за изплащане на обезщетение</span>
                <LogoIcon fill='rgb(209 213 219)' />
              </div>
              <hr tw='w-full border-t border-gray-200 mt-4' />
              <div>
                <p tw='text-[13px] font-bold mt-4'>Рекламация № {params.id}</p>
                <div tw='mt-1 flex justify-between'>
                  <span tw='text-[13px] text-gray-400'>Обезщетение по пратка</span>
                  {/* ?.replace('.', ',') */}
                  <span tw='text-[13px] text-gray-400'> {claim?.compensationAmount} лв.</span>
                </div>
              </div>
            </div>
          </WhiteBoxWrapper>
          <WhiteBoxWrapperCustom className='p-4 mt-1 '>
            <div tw='flex justify-between items-center mb-4'>
              <span tw='text-sm font-light'>Обща сума за получаване</span>
              {/* ?.replace('.', ',') */}
              <span tw='font-bold'> {claim?.compensationAmount} лв.</span>
            </div>
          </WhiteBoxWrapperCustom>
          <WhiteBoxWrapper className={`p-6 mt-5`}>
            <div>
              <h1 tw='text-sm'>Как желаете да получите сумата?</h1>
              <div tw='mt-4'>
                <div className='flex items-center'>
                  <input
                    type='radio'
                    id={'Ecoints'}
                    name={'opinion'}
                    value={'ecoints'}
                    checked={paymentMethod === 'ecoints'}
                    onChange={handleInputChange}
                    tw='focus:ring-0 focus:ring-offset-0'
                  />
                  <label
                    htmlFor={'Ecoints'}
                    style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
                    tw='ml-2.5 text-[13px]'
                    className={'text-black-100'}
                  >
                    <EcointsLogo />
                  </label>
                  <br></br>
                </div>
                {paymentMethod === 'ecoints' && (
                  <EcointsPayment
                    tel={tel}
                    setTel={setTel}
                    errorTel={errorTel}
                    setErrorTel={setErrorTel}
                  />
                )}
                <RadioField
                  key={'1'}
                  name={'opinion'}
                  label={'В офис на еконт'}
                  value={'in_office'}
                  checked={paymentMethod === 'in_office'}
                  error={''}
                  onChange={handleInputChange}
                  className='mt-4'
                  disabled={isClaimDecisionIsPayThroughEcoints ? true : false}
                />
                {paymentMethod === 'in_office' && (
                  <OfficePaymentMobile
                    showMap={showMap}
                    setShowMap={setShowMap}
                    hoveredBox={hoveredBox}
                    setHoveredBox={setHoveredBox}
                    setIsOpenMainModal={handleRouteBack}
                    refetchData={handleRouteBack}
                    office={office}
                    setOffice={setOffice}
                    defaultOffice={defaultOffice}
                    isSelectedDefault={isSelectedDefaultOffice}
                    setIsSelectedDefault={setIsSelectedDefaultOffice}
                  />
                )}
                <RadioField
                  key={'2'}
                  name={'opinion'}
                  label={'По банков път'}
                  value={'bank_type'}
                  checked={paymentMethod === 'bank_type'}
                  error={''}
                  onChange={handleInputChange}
                  className='mt-4'
                  disabled={isClaimDecisionIsPayThroughEcoints ? true : false}
                />
                {paymentMethod === 'bank_type' && (
                  <>
                    {claim && claim.bankAccounts?.length ? renderBankAccountDropdown() : ''}
                    <BankTransfer
                      iban={iban}
                      setIban={setIban}
                      bic={bic}
                      setBic={setBic}
                      bank={bank}
                      setBank={setBank}
                      name={name}
                      setName={setName}
                      errorMessageIBAN={errorMessageIBAN}
                      errorMessageBIC={errorMessageBIC}
                      errorMessageBANK={errorMessageBANK}
                      errorMessageNAME={errorMessageNAME}
                      clearErrors={clearErrorsBankType}
                      setIsOpenMainModal={handleRouteBack}
                      refetchData={handleRouteBack}
                      isMobile={true}
                      reclamationNum={params.id}
                    />
                  </>
                )}
              </div>
              {isMobile || isTablet ? (
                <div className='mt-20'>
                  <hr tw='w-full border-t border-gray-200 mt-4' />
                  <div className='flex justify-between mt-5 flex-row gap-24'>
                    <button
                      type='button'
                      className='text-blue-300 font-light text-lg flex items-center'
                      onClick={handleRouteBack}
                    >
                      <LeftArrow width='16' height='16' fill='#4877CE' viewbox='0 0 16 16' />
                      <span style={{ color: '#4877CE' }} tw='text-[15px]'>
                        Назад
                      </span>
                    </button>
                    <Button
                      type='submit'
                      disabled={
                        paymentMethod === 'in_office'
                          ? isDisabledOfficeType()
                          : paymentMethod === 'bank_type'
                          ? isDisabledBankType()
                          : paymentMethod === 'ecoints'
                          ? isDisableEcointsType()
                          : true
                      }
                      onClick={
                        paymentMethod === 'in_office'
                          ? handleSubmitOfficeType
                          : paymentMethod === 'bank_type'
                          ? handleSubmitBankType
                          : paymentMethod === 'ecoints'
                          ? handleSubmitEcointsType
                          : handleSubmit
                      }
                      variant='mini'
                      width='100%'
                    >
                      Потвърди
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <hr tw='w-full border-t border-gray-200 mt-5' />
                  <div tw='flex justify-between mt-5 mobile:(flex-col-reverse gap-5) tablet:(flex-row) laptop:(flex-row)'>
                    <button
                      type='button'
                      tw='text-[13px]'
                      style={{ fontFamily: 'Open Sans, Regular', color: '#4877CE' }}
                      onClick={handleRouteBack}
                    >
                      Назад
                    </button>
                    <Button
                      type='submit'
                      disabled={
                        paymentMethod === 'in_office'
                          ? isDisabledOfficeType()
                          : paymentMethod === 'bank_type'
                          ? isDisabledBankType()
                          : paymentMethod === 'ecoints'
                          ? isDisableEcointsType()
                          : true
                      }
                      onClick={
                        paymentMethod === 'in_office'
                          ? handleSubmitOfficeType
                          : paymentMethod === 'bank_type'
                          ? handleSubmitBankType
                          : paymentMethod === 'ecoints'
                          ? handleSubmitEcointsType
                          : handleSubmit
                      }
                      variant='cool_gray-blue'
                    >
                      Потвърди
                    </Button>
                  </div>
                </>
              )}
            </div>
          </WhiteBoxWrapper>
        </div>
      </>
    );
  };

  const renderWebApp = () => {
    return (
      <>
        <GrayBackgroundWrapper top='0rem' />
        <div
          className={`${localStorage.getItem('hideHeader') ? 'mt-10' : 'mt-24'}`}
          tw='mobile:(flex flex-col gap-10) tablet:(flex flex-row justify-center gap-10)'
        >
          <WhiteBoxWrapper className={`p-6 mobile:w-full tablet:w-2/5`}>
            <div>
              <h1 style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}>
                Изберете как ще получите сумата
              </h1>
              <div tw='mt-4'>
                <div className='flex items-center'>
                  <input
                    type='radio'
                    id={'Ecoints'}
                    name={'opinion'}
                    value={'ecoints'}
                    checked={paymentMethod === 'ecoints'}
                    onChange={handleInputChange}
                    tw='focus:ring-0 focus:ring-offset-0'
                  />
                  <label
                    htmlFor={'Ecoints'}
                    style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
                    tw='ml-2.5 text-[13px]'
                    className={'text-black-100'}
                  >
                    <EcointsLogo />
                  </label>
                  <br></br>
                </div>
                {paymentMethod === 'ecoints' && (
                  <EcointsPayment
                    tel={tel}
                    setTel={setTel}
                    errorTel={errorTel}
                    setErrorTel={setErrorTel}
                  />
                )}
                {!isClaimDecisionIsPayThroughEcoints && (
                  <div className='py-3'>
                    <input
                      type='radio'
                      id={'В офис на еконт'}
                      name={'opinion'}
                      value={'in_office'}
                      checked={paymentMethod === 'in_office'}
                      onChange={handleInputChange}
                      tw='focus:ring-0 focus:ring-offset-0'
                    />
                    <label
                      htmlFor={'В офис на Еконт'}
                      style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
                      tw='ml-2.5 text-[13px]'
                      className={'text-black-100'}
                    >
                      {'В офис на Еконт'}
                    </label>
                    <br></br>
                    {paymentMethod === 'in_office' && (
                      <OfficePayment
                        showMap={showMap}
                        setShowMap={setShowMap}
                        hoveredBox={hoveredBox}
                        setHoveredBox={setHoveredBox}
                        setIsOpenMainModal={handleRouteBack}
                        refetchData={handleRouteBack}
                        office={office}
                        setOffice={setOffice}
                        defaultOffice={defaultOffice}
                        isSelectedDefault={isSelectedDefaultOffice}
                        setIsSelectedDefault={setIsSelectedDefaultOffice}
                      />
                    )}
                  </div>
                )}
                {!isClaimDecisionIsPayThroughEcoints && (
                  <div>
                    <input
                      type='radio'
                      id={'По банков път'}
                      name={'opinion'}
                      value={'bank_type'}
                      checked={paymentMethod === 'bank_type'}
                      onChange={handleInputChange}
                      tw='focus:ring-0 focus:ring-offset-0'
                    />
                    <label
                      htmlFor={'По банков път'}
                      style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
                      tw='ml-2.5 text-[13px]'
                      className={'text-black-100'}
                    >
                      {'По банков път'}
                    </label>
                    <br></br>
                  </div>
                )}
                {paymentMethod === 'bank_type' && (
                  <>
                    {claim && claim.bankAccounts?.length ? renderBankAccountDropdown() : ''}
                    <BankTransfer
                      iban={iban}
                      setIban={setIban}
                      bic={bic}
                      setBic={setBic}
                      bank={bank}
                      setBank={setBank}
                      name={name}
                      setName={setName}
                      errorMessageIBAN={errorMessageIBAN}
                      errorMessageBIC={errorMessageBIC}
                      errorMessageBANK={errorMessageBANK}
                      errorMessageNAME={errorMessageNAME}
                      clearErrors={clearErrorsBankType}
                      setIsOpenMainModal={handleRouteBack}
                      refetchData={handleRouteBack}
                      isMobile={true}
                      reclamationNum={params.id}
                    />
                  </>
                )}
              </div>
              {isMobile || isTablet ? (
                <div className='mt-20'>
                  <hr tw='w-full border-t border-gray-200 mt-4' />
                  <div className='flex justify-between mt-5 flex-row gap-24'>
                    <button
                      type='button'
                      className='text-blue-300 font-light text-lg flex items-center'
                      onClick={handleRouteBack}
                    >
                      <LeftArrow
                        width='20'
                        height='20'
                        fill='rgb(0, 63, 133)'
                        viewbox='0 0 16 16'
                      />
                      <span>Назад</span>
                    </button>
                    <Button
                      type='submit'
                      disabled={
                        paymentMethod === 'in_office'
                          ? isDisabledOfficeType()
                          : paymentMethod === 'bank_type'
                          ? isDisabledBankType()
                          : paymentMethod === 'ecoints'
                          ? isDisableEcointsType()
                          : true
                      }
                      onClick={
                        paymentMethod === 'in_office'
                          ? handleSubmitOfficeType
                          : paymentMethod === 'bank_type'
                          ? handleSubmitBankType
                          : paymentMethod === 'ecoints'
                          ? handleSubmitEcointsType
                          : handleSubmit
                      }
                      variant='mini'
                      width='100%'
                    >
                      Потвърди
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <hr tw='w-full border-t border-gray-200 mt-5' />
                  <div tw='flex justify-between mt-5 mobile:(flex-col-reverse gap-5) tablet:(flex-row) laptop:(flex-row)'>
                    <button
                      type='button'
                      tw='text-[13px]'
                      style={{ fontFamily: 'Open Sans, Regular', color: '#4877CE' }}
                      onClick={handleRouteBack}
                    >
                      Назад
                    </button>
                    <Button
                      type='submit'
                      disabled={
                        paymentMethod === 'in_office'
                          ? isDisabledOfficeType()
                          : paymentMethod === 'bank_type'
                          ? isDisabledBankType()
                          : paymentMethod === 'ecoints'
                          ? isDisableEcointsType()
                          : true
                      }
                      onClick={
                        paymentMethod === 'in_office'
                          ? handleSubmitOfficeType
                          : paymentMethod === 'bank_type'
                          ? handleSubmitBankType
                          : paymentMethod === 'ecoints'
                          ? handleSubmitEcointsType
                          : handleSubmit
                      }
                      variant='cool_gray-blue'
                    >
                      Потвърди
                    </Button>
                  </div>
                </>
              )}
            </div>
          </WhiteBoxWrapper>
          <div>
            <WhiteBoxWrapper className='p-4 w-6/7'>
              <div>
                <div tw='flex justify-between gap-5'>
                  <span tw='text-[10px] text-gray-400 w-2/4'>
                    Документ за изплащане на обезщетение
                  </span>
                  <LogoIconWeb fill='rgb(125, 125, 125)' className='opacity-20 mb-2' />
                </div>
                <hr tw='w-full border-t border-gray-200 mt-4' />
                <div>
                  <p tw='text-[11px] font-bold mt-4' style={{ fontFamily: 'Open Sans, Bold' }}>
                    Рекламация № {params.id}
                  </p>
                  <div tw='mt-1 flex justify-between'>
                    <span
                      tw='text-[11px] tracking-wider'
                      style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}
                    >
                      Обезщетение по пратка
                    </span>
                    <span
                      tw='text-[11px]'
                      style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}
                    >
                      {/* ?.replace('.', ',') */}
                      {claim?.compensationAmount} лв.
                    </span>
                  </div>
                </div>
                <hr tw='w-full border-t-2 border-gray-200 mt-4' />
                <div tw='flex justify-between items-center mt-3'>
                  <span
                    tw='text-[11px] tracking-wider'
                    style={{ fontFamily: 'Open Sans, Regular', color: '#424242' }}
                  >
                    Обща сума за получаване
                  </span>
                  <span
                    tw='font-bold text-sm'
                    style={{ fontFamily: 'Open Sans, Regular', color: '#424242' }}
                  >
                    {claim?.compensationAmount} лв.
                  </span>
                </div>
              </div>
            </WhiteBoxWrapper>
          </div>
        </div>
      </>
    );
  };

  if (showGlobalError) {
    return (
      <AlertPopup
        show={showGlobalError}
        text='Неуспешeн ъпдейт по рекламация'
        subText={globalError}
        onClick={() => {
          setGlobalError('');
          setShowGlobalError(false);
          handleRouteBack();
        }}
        onClose={() => {
          setGlobalError('');
          setShowGlobalError(false);
          handleRouteBack();
        }}
      />
    );
  }

  if (loading)
    return (
      <Wrapper>
        <LoadingSpinner />
      </Wrapper>
    );

  if (isEcointsNotificationSent) {
    return (
      <Wrapper>
        <>
          <GrayBackgroundWrapper top='0rem' />
          <EcointsNotificationSent resendNotification={handleSubmitEcointsType} />
        </>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <>
        {isMobile || isTablet ? renderMobileApp() : renderWebApp()}
        {!isOpenEcointsModal && claim && claim.ecointsPhones && !claim.ecointsPhones.length && (
          <div
            style={{ boxShadow: '4px 7px 25px #00000017' }}
            tw='flex flex-row items-center bg-white py-2 px-4 gap-5 rounded-lg cursor-pointer mobile:(fixed bottom-4 right-auto) tablet:(absolute bottom-20 right-20)'
            onClick={() => setIsOpenEcointsModal(true)}
          >
            <div>
              <h1
                tw='text-[13px] tracking-wide'
                style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
              >
                Дигиталният портфейл
              </h1>
              <EcointsLogo width='80' height='34' />
            </div>
            <div>
              <img
                width={60}
                height={60}
                src={`${process.env.PUBLIC_URL}/images/qr_ecoints.png`}
                alt='ecoints-toast'
              />
            </div>
          </div>
        )}
        <ModalEcointsDIgitalWallet open={isOpenEcointsModal} setOpen={setIsOpenEcointsModal} />
        <ModalMIssingEcointsNumber
          open={isOpenModalMissingEcointsNumber}
          setOpen={setIsOpenModalMissingEcointsNumber}
        />
      </>
    </Wrapper>
  );
};

export default PayOnline;
