import React, { useEffect } from 'react';
import tw from 'twin.macro';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

interface Tel {
  tel: any;
  setTel: any;
  errorTel: String;
  setErrorTel: Function;
}

const EcointsPayment = ({ tel, setTel, errorTel, setErrorTel }: Tel) => {
  useEffect(() => {
    if (tel && !isValidPhoneNumber(tel.toString())) {
      setErrorTel('Невалиден номер');
    } else {
      setErrorTel('');
    }
  }, [tel, setErrorTel]);

  return (
    <div tw='ml-10'>
      <p tw='text-[11px] my-2' style={{ fontFamily: 'Open Sans, Regular', color: '#424242' }}>
        Телефонен номер, на който ще получите известие за плащане
      </p>

      <PhoneInput
        className='phone-input-ecoints'
        defaultCountry='BG'
        countries={['BG']}
        placeholder='Телефонен номер'
        value={tel}
        onChange={setTel}
      />
      <p tw='text-red-500 text-[13px]'>{errorTel}</p>
    </div>
  );
};

export default EcointsPayment;
