import { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import RemoveButton from './RemoveButton';
import LoadingSpinner from '../../common/LoadingSpinner';

import PdfIcon from '../../icons/PdfIcon';
import VideoIcon from '../../icons/VideoIcon';

import { File } from '../../../types/Form';

import { downloadFile } from '../../../services/actions';
import useQuery from '../../../hooks/use-query-hook';

import tw from 'twin.macro';

const imagesTypes = ['png', 'jpg', 'jpeg', 'svg'];

const PreviewContainer = tw.div`flex  flex-col items-center text-center justify-center`;

interface Props {
  data: File;
  index: number;
  isLast?: boolean;
  onFileDelete?: (index: number) => void; //update to event
}

const FilePreview = ({ data, index, isLast, onFileDelete }: Props) => {
  const { idUser, hashKey, hashKey2 } = useQuery();
  const isLocalFile = !!data.file;
  const [loading, setLoading] = useState(!isLocalFile);
  const [fileData, setFileData] = useState<any>(null);

  useEffect(() => {
    if (!isLocalFile) {
      const downloadUrl = data.previewUrl;
      const queryParameters = {
        idUser,
        hashKey,
        hashKey2,
      };

      downloadFile(downloadUrl as string, queryParameters)
        .then((fileUrl) => {
          setLoading(false);
          setFileData(fileUrl || null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLocalFile, data.previewUrl, idUser, hashKey, hashKey2]);

  const onFileClick = () => {
    if (!fileData) return null;
    const url = data.previewUrl as string;
    const splitedUrl = url.split('/');
    const fileName = splitedUrl[splitedUrl.length - 1];

    FileSaver.saveAs(fileData, fileName);
  };

  const renderImage = (url: string) => {
    return (
      <PreviewContainer>
        {url ? (
          <img
            src={url as string}
            onError={(e) => {
              console.log('Here  error');
            }}
            tw='max-h-32 w-32 rounded'
            alt='No Preview'
          />
        ) : null}
      </PreviewContainer>
    );
  };

  const renderPreview = () => {
    if (isLocalFile) {
      const isImage = !!data.previewUrl;
      const isPdf = data.file?.type.toLocaleLowerCase().indexOf('pdf') !== -1;
      const isVideo = data.file?.type.toLocaleLowerCase().indexOf('video') !== -1;
      const isExcel = data.file?.type.toLocaleLowerCase().indexOf('excel') !== -1 || data.file?.type.toLocaleLowerCase().indexOf('sheet') !== -1;
      const isDocument = data.file?.type.toLocaleLowerCase().indexOf('wordprocess') !== -1 || data.file?.type.toLocaleLowerCase().indexOf('msword') !== -1;

      if (isImage) {
        return renderImage(data.previewUrl as string);
      }

      if (isPdf || isVideo || isExcel || isDocument) {
        return (
          <PreviewContainer>
            {isPdf && <PdfIcon className='mb-2.5' />}
            {isVideo && <VideoIcon className='mb-2.5 h-7.5 w-7.5' />}
            {isExcel && (
              <img
                width={32}
                height={32}
                src={`${process.env.PUBLIC_URL}/images/xlsx.svg`} //fileUrl
                alt={'excel'}
                className='rounded-lg mb-2.5 cursor-pointer'
              />
            )}
            {isDocument && (
              <img
                width={32}
                height={32}
                src={`${process.env.PUBLIC_URL}/images/document.svg`} //fileUrl
                alt={'document'}
                className='rounded-lg mb-2.5 cursor-pointer'
              />
            )}
            <span tw='text-xs text-blue-100 font-light'>{data.file?.name?.substring(0,15)}...</span>
          </PreviewContainer>
        );
      }
    } else {
      if (!fileData) return null;
      const url = data.previewUrl as string;

      const isPdf = url.indexOf('pdf') !== -1;
      if (isPdf) {
        return (
          <PreviewContainer>
            <PdfIcon className='mb-2.5' />
            <span tw='text-xs text-blue-100 font-light'>PDF</span>
          </PreviewContainer>
        );
      }

      const isImage = imagesTypes.some((imageType) => url.indexOf(imageType) !== -1);
      if (isImage) {
        const blob = new Blob([fileData], { type: fileData.type });

        const imageUrl = window.URL.createObjectURL(blob);
        return renderImage(imageUrl || '');
      }

      return (
        <PreviewContainer>
          <VideoIcon className='mb-2.5 h-7.5 w-7.5' />
          <span tw='text-xs text-blue-100 font-light'>Video</span>
        </PreviewContainer>
      );
    }
  };

  return (
    <div
      css={[
        tw`flex items-center justify-center box-border bg-grey-200 rounded-md relative w-32 h-32`,
        // tw`mobile:(h-22.5 w-22.5 m-2) tablet:(w-45 h-25 m-0 mr-4)`,
        isLast && tw`mr-0`,
        !!data.previewUrl && tw`bg-white`,
        tw`text-center`,
        !isLocalFile && tw`cursor-pointer`,
      ]}
      onClick={onFileClick}
    >
      {loading ? (
        <LoadingSpinner size={50} />
      ) : (
        <>
          {' '}
          {onFileDelete && <RemoveButton onClick={() => onFileDelete(index)} />}
          {renderPreview()}
        </>
      )}
    </div>
  );
};

export default FilePreview;
