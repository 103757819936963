import tw, { css } from 'twin.macro';
import Footer from './Footer';

import Header from './Header';

interface Props {
  children: JSX.Element;
  buttonComponent: JSX.Element;
  isLarge?: boolean;
}

const CreateClaimWrapper = ({ children, buttonComponent, isLarge }: Props) => {
  return (
    <div tw='min-h-screen'>
      <Header />
      <div
        css={[
          tw`p-5`,
          css`
            @media (max-width: 768px) {
              min-height: calc(100vh - 8.15rem);
            }
            min-height: calc(100vh - 3.75rem);
          `,
          // css`
          //   min-height: calc(100vh - ${isLarge ? `10rem` : `8rem`});
          // @media (min-width: 1024px) {
          //   min-height: calc(100vh - 3.75rem);
          // }
          // `,
        ]}
      >
        <div tw='mx-auto laptop:max-w-[40rem] h-full'>{children}</div>
      </div>
      {buttonComponent}
      <Footer />
    </div>
  );
};

export default CreateClaimWrapper;
