import React, { useState } from 'react';
import WhiteBoxWrapper from '../layout/WhiteBoxWrapper';
import tw from 'twin.macro';
import RightArrow from '../icons/RightArrow';
import RightArrowModernIcon from '../icons/RightArrowModernIcon';
import { isMobile, isTablet } from 'react-device-detect';
import LeftArrow from '../icons/LeftArrow';
import { useHistory } from 'react-router-dom';
import { off } from 'process';

const EcointsNotificationSent = ({ resendNotification }: any) => {
  const [gifKey, setGifKey] = useState('1');
  const history = useHistory();

  const handleRouteBack = () => {
    history.goBack();
  };

  return (
    <div
      className={`${
        localStorage.getItem('hideHeader') ? 'mt-5' : 'mt-24'
      } w-full flex flex-col items-center`}
    >
      <WhiteBoxWrapper
        className={`${isMobile || isTablet ? 'w-full' : 'w-3/6'}`}
        tw='rounded-2xl pt-5 pl-5 pr-5'
      >
        <div className='flex flex-col justify-between'>
          <div className='text-center'>
            <h1 tw='text-[18px]' style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}>
              Успешно заявихте обезщетение.
            </h1>
            <h1 tw='text-[18px]' style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}>
              Нужно е само да го потвърдите
            </h1>
            <p
              tw='text-[13px] mt-4 text-center'
              style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}
            >
              Липсва известие на екрана Ви?
            </p>
            <p
              tw='text-[13px] mt-1 flex gap-1 justify-center items-center cursor-pointer'
              style={{
                fontFamily: 'Open Sans, Regular',
                color: gifKey === '1' ? '#4877CE' : '#737985',
              }}
              onClick={() => {
                if (gifKey === '1') {
                  resendNotification();
                  setGifKey((prev) => prev + '1');
                } else return;
              }}
            >
              Изпрати известието отново{' '}
              <RightArrowModernIcon width='16' height='16' fill='#4877CE' />
            </p>
          </div>
          <div className='flex justify-center mt-5'>
            {gifKey && (
              <img
                key={gifKey}
                width={360}
                height={380}
                src={`${process.env.PUBLIC_URL}/gifs/notification_sent.gif?${gifKey}`}
                alt='ecoints-toast'
              />
            )}
          </div>
        </div>
      </WhiteBoxWrapper>
      <p
        tw='text-[12px] mt-3 flex gap-1 justify-center items-center cursor-pointer py-1 hover:(bg-grey-200 px-5 rounded-full)'
        style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
        onClick={handleRouteBack}
      >
        <LeftArrow width='14' height='14' fill='#212121' />
        Обратно към рекламацията
      </p>
    </div>
  );
};

export default EcointsNotificationSent;
