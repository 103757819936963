import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Windmill } from '@windmill/react-ui';
import Routes from './Routes';
import LoadingSpinner from './components/common/LoadingSpinner';
import { ToastContainer } from 'react-toastify';

import tw from 'twin.macro';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/bg'; // without this line it didn't work
import moment from 'moment';

const CLDR_MONTHS = [
  'Януари',
  'Февруари',
  'Март',
  'Април',
  'Май',
  'Юни',
  'Юли',
  'Август',
  'Септември',
  'Октомври',
  'Ноември',
  'Декември',
];

moment.updateLocale('bg', { months: CLDR_MONTHS });
moment.locale('bg');

function App() {
  return (
    <BrowserRouter>
      <Windmill>
        <Suspense fallback={<LoadingSpinner />}>
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            theme='light'
          />
          <Routes />
        </Suspense>
      </Windmill>
    </BrowserRouter>
  );
}

export default App;

// #07bc0c
