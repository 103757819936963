import 'twin.macro';
import AlertPopup from '../components/layout/AlertPopup';
import Wrapper from '../components/layout/Wrapper';

const ErrorInvalidHash = () => {
  return (
    <Wrapper>
      <AlertPopup
        show={true}
        text='Грешка'
        subText='Моля, проверете дали въведената от вас информация е вярна.'
        onClick={() => window.location.reload()}
        phoneText='Свършете се с наш колега.'
        primaryButtonText='Опитай пак'
        secondaryButton={true}
      />
    </Wrapper>
  );
};

export default ErrorInvalidHash;
