import React from 'react';

import tw, { css } from 'twin.macro';

interface Props {
  value?: string;
  name: string;
  label?: string;
  large?: boolean;
  placeholder?: string;
  error?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void; //update to event
}

const InputField = ({ value, large, name, label, placeholder, onChange, error }: Props) => {
  const renderLargePlaceholder = () => (
    <span tw='absolute top-3.5 left-3.5 font-body italic cursor-default text-sm font-light text-gray-400'>
      {placeholder}
    </span>
  );
  return (
    <label tw='flex flex-wrap items-center cursor-pointer relative w-full' htmlFor={name}>
      {label && (
        <span tw='block w-full pb-1 font-light text-sm font-body text-black-100'>{label}</span>
      )}
      {large && renderLargePlaceholder()}
      <input
        css={[
          tw`w-full border-grey-light text-[13px] p-3.5 border rounded-md placeholder-grey-400 resize-none`,
          css`
            &::placeholder {
              ${tw`text-[13px] text-gray-400 pt-1`}
            }
          `,
          large ? tw`placeholder-transparent pt-11` : tw`h-9`,
          error && tw`border-red-500`,
        ]}
        style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </label>
  );
};

export default InputField;
