import React from 'react';

function OfficeIcon() {
  return (
    <svg
      id='Pin_office'
      data-name='Pin office'
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='48'
      viewBox='0 0 32 48'
    >
      <path
        id='dropshape'
        d='M32,15.412a16.857,16.857,0,0,1-1.746,7.549L17.011,47.406a1.147,1.147,0,0,1-2.01,0L1.574,22.619A17.145,17.145,0,0,1,0,15.412C0,6.739,7.373,0,16.009,0S32,6.735,32,15.412Z'
        transform='translate(0)'
        fill='#003f85'
      />
      <g id='house_icon' data-name='house icon' transform='translate(6.857 7.543)'>
        <path id='Fill_1' data-name='Fill 1' d='M3.143.457l-3,4h18l-3-4Z' fill='#fff' />
        <path
          id='Fill_2'
          data-name='Fill 2'
          d='M16.108,10.742h-16v-2h1v-9h14v9h1v2Zm-10-8v6h4v-6Z'
          transform='translate(1.035 5.714)'
          fill='#fff'
        />
      </g>
    </svg>
  );
}

export default OfficeIcon;
