import { useState } from 'react';
import { getOfficeIcon } from '../../utils/claimItemHelpers';
import OfficeLocator from './OfficeLocator';
import tw from 'twin.macro';
import OfficeIconMobile from '../icons/OfficeMobile';

interface Props {
  showMap: boolean;
  setShowMap: Function;
  hoveredBox: string;
  setHoveredBox: Function;
  setIsOpenMainModal: Function;
  refetchData: Function;
  office: any;
  setOffice: Function;
  defaultOffice: any;
  isSelectedDefault: boolean;
  setIsSelectedDefault: Function;
}

const OfficePayment = ({
  showMap,
  setShowMap,
  office,
  setOffice,
  defaultOffice,
  isSelectedDefault,
  setIsSelectedDefault,
}: Props) => {
  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { type, name, checked, value } = e.target;

    setIsSelectedDefault(checked);
  };

  const OfficeIcon = getOfficeIcon(office?.name);

  return (
    <div>
      <div className='flex flex-col gap-2 w-50 mt-2 mobile:flex-wrap tablet:flex-wrap laptop:flex-nowrap justify-between'>
        {!showMap && !office?.id && (
          <div tw='ml-[1.8rem]'>
            <label
              tw='inline-flex items-center cursor-pointer relative'
              htmlFor={'recommended_office'}
            >
              <input
                tw='w-4 h-4 box-border appearance-none border-grey-light border rounded-md focus:ring-0 focus:ring-offset-0'
                type='checkbox'
                name={'recommended_office'}
                id={'recommended_office'}
                checked={isSelectedDefault}
                onChange={handleInputChange}
              />
              <span tw='flex flex-col ml-3'>
                <span
                  tw='text-[13px]'
                  style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
                >
                  {defaultOffice?.address?.fullAddress}
                </span>
                <span
                  tw='text-[11px]'
                  style={{ fontFamily: 'Open Sans, Regular', color: '#747373' }}
                >
                  Често избиран
                </span>
              </span>
            </label>
          </div>
        )}
        {!showMap && office?.id && (
          <div className={`w-full rounded-lg flex w-full`}>
            <div tw='flex justify-center items-center ml-[1.3rem]'>
              <OfficeIconMobile />
            </div>
            <div className='flex flex-col ml-2'>
              <span tw='text-[15px]' style={{ fontFamily: 'Open Sans, Bold' }}>
                <b>{office?.name}</b>
              </span>
              <span tw='text-[13px]' style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}>
                {office?.address?.fullAddress}
              </span>
            </div>
          </div>
        )}
        {!isSelectedDefault && (
          <OfficeLocator
            showMap={showMap}
            setShowMap={setShowMap}
            setOffice={setOffice}
            activatorText={'Избери на картата'}
          />
        )}
      </div>
    </div>
  );
};

export default OfficePayment;
