import { useEffect, useState } from 'react';

import useQuery from './use-query-hook';
import useAuthRedirect from './use-auth-redirect-hook';
import routePaths from '../route-paths';
import { signIn } from '../services/actions';
import { getAccessToken, getAuthRefreshToken, setAuthKeys } from '../services/localStorage';
import { AUTH_LOGIN_URL, BASE_URL } from '../constants';

interface Response {
  isMount: boolean;
  authLoading: boolean;
  isAuthenticated: boolean;
  error?: string;
}

const useAuthHook = (
  skipAuth?: boolean,
  skipAuthFullParameters?: boolean,
  redirect?: string,
  params?: any
): Response => {
  const { code } = useQuery();
  const queryParameters = useQuery();

  const [isMount, setMount] = useState(skipAuth || false);
  const [loading, setLoading] = useState(skipAuth ? false : true);
  const [isAuthenticated, setAuth] = useState(skipAuth || false);
  const [error, setError] = useState('');
  const { onRedirect } = useAuthRedirect();

  const accessToken = getAccessToken();
  const refreshToken = getAuthRefreshToken();

  useEffect(() => {
    if (queryParameters.sourceApp) localStorage.setItem('sourceApp', queryParameters.sourceApp);

    if (
      (queryParameters.token && !accessToken) ||
      (queryParameters.token && queryParameters.token !== accessToken)
    ) {
      setAuthKeys(queryParameters.token, queryParameters.token);
      setLoading(false);
      setMount(true);
      setAuth(true);
      window.location.reload();
      return;
    }
    if (skipAuthFullParameters) {
      setAuthKeys();
      onRedirect(routePaths.root, queryParameters, queryParameters);
      return;
    }
    if (skipAuth) {
      setAuthKeys();
      if (redirect) {
        onRedirect(redirect, params || {}, queryParameters);
      } else {
        onRedirect(routePaths.myClaims, queryParameters, queryParameters);
      }
      return;
    }

    if (!accessToken && !code) {
      setLoading(false);
      window.location.replace(AUTH_LOGIN_URL as string);
      return;
    }

    if (!isMount && !accessToken) {
      signIn(code).then(() => {
        const sourceApp = localStorage.getItem('sourceApp');

        const updatedAuthKey = getAccessToken();
        if (updatedAuthKey) {
          setLoading(false);
          setMount(true);
          setAuth(true);
          setError('');

          localStorage.removeItem('showFirstClaimBox');
          if (sourceApp) {
            window.location.replace(`${BASE_URL}/?sourceApp=${sourceApp}` as string);
          } else {
            window.location.replace(BASE_URL as string);
          }
        } else {
          setError('Неуспешен логин.');
        }
        return;
      });
    }

    if (accessToken || queryParameters.token) {
      setLoading(false);
      setMount(true);
      setAuth(true);
    }
  }, [skipAuth, code, isMount, accessToken, refreshToken]);

  return {
    isAuthenticated,
    authLoading: loading,
    isMount,
    error,
  };
};

export default useAuthHook;
