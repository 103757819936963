export const enum Status {
  Done = 'compensationPayed',
  Pending = 'inProcess',
  Received = 'offered',
  DoneUnpayed = 'noCompensation',
  Reject = 'rejected',
}

export interface ClaimItemType {
  canUseBankCompensationPayMethod: boolean;
  decisionFileUrl: string;
  paymentOptions: object;
  compensationStatus: Status;
  monitoringPersonName: string;
  monitoringPersonPhone: string;
  offerCode: string;
  offerDescription: string;
  number: string;
  date: string;
  status: string;
  deadlineDays: string;
  history: any[];
  files: any[];
  claimCode?: string;
  shipmentNumbers?: string;
  note?: string;
  label?: string;
  id?: number;

  // label?: string;
  // status: Status;
  // date?: string;
  // deadlineDays?: number; //terms
  // number?: string;
  // shipmentId: string;
  // description: string;
  // id?: number;
  // userId?: string;
  // createdAt?: string;
  // reasons: string[];
  // files: any[];
  // serviceNumber?: string;
  // // new api fields
  // regardingShipment?: string;
  // finishedAt?: string;
  // responseTime: string;
}
