// import { FieldsNames, ReturnFormData } from '../../types/Form';
import Tabs from '../layout/Tabs';
import EmptyItem from '../list/EmptyItem';
import ClaimsListContent from '../list/ClaimsListContent';
import Button from '../Button/Button';

import { ClaimItemType, Status } from '../../types/Data';
import tw, { css } from 'twin.macro';
import useAuthRedirect from '../../hooks/use-auth-redirect-hook';
import routePaths from '../../route-paths';
import useQuery from '../../hooks/use-query-hook';
import ButtonWrapper from '../Button/ButtonWrapper';
import { hardcodedData } from '../../utils/hardcodedClaimsList';
import { useCallback, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import LeftArrow from '../icons/LeftArrow';
import useSwipeHook from '../../hooks/use-swipe-hook';

declare global {
  interface Window {
    ReactNativeWebView: any; // 👈️ turn off type checking
  }
}

interface Props {
  listData: ClaimItemType[];
  activePage: number;
  active: number;
  finish: number;
  refetchData: Function;
  setActivePage: Function;
  loading?: boolean;
  setLoading?: Function;
}

const ClaimsList = ({
  listData,
  activePage,
  active,
  finish,
  setActivePage,
  refetchData,
  loading,
  setLoading,
}: Props) => {
  const { onRedirect } = useAuthRedirect();
  const queryParameters = useQuery();

  // TO DO: set constants to the listData

  const claimsCount = active + finish; // listData.length;

  const getClaimsListOptions = useCallback(
    (
      data: ClaimItemType[],
      activePage: number,
      active: number,
      finish: number,
      setActivePage: Function,
      refetchData: Function
    ) => [
      {
        label: `Активни (${active})`,
        index: 0,
        content: (
          <ClaimsListContent
            activePage={activePage ? activePage - 1 : 0}
            listData={data}
            totalResults={active}
            setActivePage={setActivePage}
            refetchData={refetchData}
            type='unprocessed'
          />
        ),
      },
      {
        label: `Приключени (${finish})`,
        index: 1,
        disabled: finish === 0,
        content: (
          <ClaimsListContent
            activePage={activePage ? activePage - 1 : 0}
            listData={data}
            totalResults={finish}
            setActivePage={setActivePage}
            refetchData={refetchData}
            type='processed'
          />
        ),
      },
      // <EmptyItem>Нямате рекламации от избрания тип за последните 6 месеца.</EmptyItem>,
    ],
    [listData, activePage]
  );

  // const claimsListOptions = getClaimsListOptions(listData, activePage, active, finish, refetchData); //listData

  const handleClick = () => {
    onRedirect(
      routePaths.root,
      { ...queryParameters, skipNumbers: 'true' },
      { ...queryParameters, skipNumbers: 'true' }
    );
  };

  const handleMobileWebViewExit = () => {
    if (window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('CloseClaimsApp');
    } else {
      handleClick();
    }
  };

  return (
    <div tw='font-body'>
      <Tabs
        activeTab={0}
        tabList={getClaimsListOptions(
          listData,
          activePage,
          active,
          finish,
          setActivePage,
          refetchData
        )}
        refetchData={refetchData}
        setActivePage={setActivePage}
        activePage={activePage}
        loading={loading}
        setLoading={setLoading}
        totalResults={active + finish}
      />
    </div>
  );
};

export default ClaimsList;
