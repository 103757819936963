import { isMobile, isTablet } from 'react-device-detect';
import { css, styled } from 'twin.macro';
import ReactModal from 'react-modal';
import XIcon from '../icons/XIcon';

export interface Props {
  open: boolean;
  setOpen: any;
}

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.486)',
    zIndex: 100024234232342342,
  },
  content: {
    border: 'none',
    borderRadius: '12px',
    top: '50%',
    left: '50%',
    minWidth: '360px',
    maxWidth: '640px',
    height: isMobile || isTablet ? '80%' : '260px',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalMIssingEcointsNumber = ({ open, setOpen }: Props) => {
  return (
    <ReactModal isOpen={open} onRequestClose={() => setOpen(false)} style={modalStyle}>
      <div className='p-2 flex flex-col justify-between gap-8'>
        <div className='w-full flex flex-col gap-1.5'>
          <div className='w-full flex justify-end'>
            <XIcon
              tw='cursor-pointer'
              fill='#212121'
              width='16'
              height='16'
              onClick={() => setOpen(false)}
            />
          </div>
          <h1 className='text-2xl' style={{ fontFamily: 'Open Sans, Bold', color: '#212121' }}>
            Липсва профил с този номер
          </h1>
          <div className='text-base'>
            <p style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}>
              Въведи номера, с който си се регистрирал в ecoints или изтегли приложението.
            </p>
          </div>
        </div>
        <div className='flex flex-row flex-wrap justify-between gap-4'>
          <div className='flex flex-row gap-2'>
            <img
              width={isMobile ? 90 : 100}
              height={40}
              src={`${process.env.PUBLIC_URL}/images/app_store.svg`}
              alt='ecoints-toast'
            />
            <img
              width={isMobile ? 100 : 110}
              height={40}
              src={`${process.env.PUBLIC_URL}/images/huawei.svg`}
              alt='ecoints-toast'
            />
            <img
              width={isMobile ? 100 : 110}
              height={40}
              src={`${process.env.PUBLIC_URL}/images/google_play.svg`}
              alt='ecoints-toast'
            />
          </div>
          <div
            className={`flex flex-row justify-center items-center self-center ${
              isMobile && 'w-full'
            }`}
          >
            <div className='flex flex-col'>
              <span
                className='text-xs'
                style={{ fontFamily: 'Open Sans, Regular', color: '#616161' }}
              >
                Сканирай
              </span>
              <span
                className='text-xs'
                style={{ fontFamily: 'Open Sans, Regular', color: '#616161' }}
              >
                и изтегли
              </span>
            </div>
            <div>
              <img
                width={60}
                height={60}
                src={`${process.env.PUBLIC_URL}/images/qr_ecoints.png`}
                alt='ecoints-toast'
              />
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalMIssingEcointsNumber;
