import useAuthRedirect from '../../../hooks/use-auth-redirect-hook';
import useQuery from '../../../hooks/use-query-hook';
import Dropdown from '../Dropdown/';
import routePaths from '../../../route-paths';
import ProfileIcon from '../../icons/ProfileIcon';
import { AUTH_LOGIN_URL, AUTH_LOGOUT_URL, BASE_URL, ProfileOptions } from '../../../constants';

import 'twin.macro';

const ProfileDropdown = () => {
  const queryParameters = useQuery();
  const { onRedirect } = useAuthRedirect();

  const handleClick = (url: string) => {
    if (url === 'logout') {
      localStorage.clear();
      window.location.assign(AUTH_LOGOUT_URL as string);
      // window.location.replace(BASE_URL as string);
    } else if (url === routePaths.root)
      onRedirect(
        url,
        { ...queryParameters, skipNumbers: 'true' },
        { ...queryParameters, skipNumbers: 'true' }
      );
    else {
      if (localStorage.getItem('currentTab')) {
        localStorage.removeItem('currentTab');
      }
      onRedirect(url, queryParameters, queryParameters);
    }
  };

  return (
    <Dropdown
      options={ProfileOptions}
      onClick={handleClick}
      icon={<ProfileIcon className='text-3xl' />}
    />
  );
};

export default ProfileDropdown;
