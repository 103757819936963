import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import ImageIcon from '../../icons/ImageIcon';
import VideoIcon from '../../icons/VideoIcon';
import 'twin.macro';
import PlusIcon from '../../icons/PlusIcon';

interface Props {
  children?: JSX.Element;
}

const DefaultView = ({ children }: Props) => {
  return (
    <div tw='flex flex-wrap items-center justify-center box-border p-5'>
      <div tw='flex items-center justify-center'>
        {!children && <PlusIcon />}
        {children}
        {/* <ImageIcon className='mr-3.5' />
        <VideoIcon className='mr-3.5' />
        <FontAwesomeIcon icon={faFile} color='#29427E' /> */}
      </div>
      {!children && (
        <p tw='text-gray-400 italic w-full text-center text-[0.9rem] font-light font-body'>Прикачете файл</p>
      )}
    </div>
  );
};

export default DefaultView;
