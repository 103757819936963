export interface File {
  file: any;
  previewUrl: string | null;
  data?: any;
  // loading?: boolean;
}

export interface UploadFile {
  index: number;
  id: string;
  thumbnailLink: string;
  mimeType: string;
  name: string;
  loading: boolean;
  error: any;
}

export interface UploadFileState {
  [key: number]: UploadFile;
}

export enum FieldsNames {
  Checkbox1 = 'checkbox1',
  Checkbox2 = 'checkbox2',
  Checkbox3 = 'checkbox3',
  Checkbox4 = 'checkbox4',
  Claimer = 'claimer',
  RegardingShipment = 'regardingShipment',
  Other = 'other',
  Files = 'files',
}

export interface ReturnFormData {
  [FieldsNames.Checkbox1]: boolean;
  [FieldsNames.Checkbox2]: boolean;
  [FieldsNames.Checkbox3]: boolean;
  [FieldsNames.Checkbox4]: boolean;
  [FieldsNames.Claimer]: string;
  [FieldsNames.RegardingShipment]?: string;
  [FieldsNames.Other]: string;
  [FieldsNames.Files]: File[];
  // [FieldsNames.Files]: UploadFileState;
}

interface CheckboxValue {
  label: string;
  claimCode: string;
}

interface CheckboxValuesTypes {
  [key: string]: CheckboxValue;
}

export const checkboxValues: CheckboxValuesTypes = {
  [FieldsNames.Checkbox1]: {
    label: 'Забавена доставка',
    claimCode: '0001',
  },
  [FieldsNames.Checkbox2]: {
    label: 'Повредена пратка',
    claimCode: '0010',
  },
  [FieldsNames.Checkbox3]: {
    label: 'Липсваща пратка или съдържание',
    claimCode: '0003',
  },
  [FieldsNames.Checkbox4]: {
    label: 'Друго',
    claimCode: '0041',
  },
};

export const radioValues = [
  {
    label: 'Получател на пратката',
    value: 'receiver',
  },
  {
    label: 'Подател на пратката',
    value: 'sender',
  },
];

export const opinionAgreementRadioValues = [
  {
    label: 'Съгласявам се със становището',
    value: '1',
  },
  {
    label: 'Не съм съгласен със становището',
    value: '2',
  },
];
