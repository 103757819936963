// import React, { useEffect, useCallback, useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
// import useGoogleapi from '../../../hooks/use-googleapi-hook';
// import { uploadFile } from '../../../services/googleDriveService';
import FilesList from './FilesList';
import DefaultView from './DefaultView';

import { File } from '../../../types/Form';

// import { UploadFile, UploadFileState, File } from '../../../types/Form';

import tw from 'twin.macro';

const MAX_FILES_COUNT = 5;

interface Props {
  value: File[];
  // initialFiles: UploadFileState;
  name: string;
  placeholder?: string;
  error?: string;
  onChange: (files: File[]) => void;
  // onChange: (files: UploadFileState) => void;
}

const FileUploaderField = ({ value, placeholder, onChange, error }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [] as File[];

      // @ts-ignore
      acceptedFiles.forEach((file) => {
        const isImage = file.type.toLocaleLowerCase().indexOf('image') !== -1;
        if (isImage) {
          const imageUrl = URL.createObjectURL(file);
          newFiles.push({
            previewUrl: imageUrl,
            file,
          });
        } else {
          newFiles.push({
            previewUrl: null,
            file,
          });
        }
        // uploadFileToDriveAndUpdateState(file, index)
      });
      const allFiles: File[] = [...value, ...newFiles]; //save all files here

      // @ts-ignore
      onChange(allFiles);
    },
    [onChange, value]
    // [gapiClient, setUploadingStatus]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES_COUNT,
  });

  const isFilesExisted = value.length > 0;
  // const uploadedFilesCount = Object.keys(uploadingFilesStatus).length;
  // const isFilesUploaded = uploadedFilesCount > 0;

  const handleRemoveFile = (deleteIndex: number) => {
    const updatedFiles = value.filter((_item, index) => index !== deleteIndex);
    onChange(updatedFiles);
  };

  const renderFiles = () => {
    if (!isFilesExisted) {
      return null;
    } else {
      return (
        <DefaultView>
          <FilesList onFileDelete={handleRemoveFile} files={value} />
        </DefaultView>
      );
    }
  };

  const renderFilesView = () => {
    if (isFilesExisted) {
      return <FilesList onFileDelete={handleRemoveFile} files={value} />;
    }
    // if (isFilesUploaded) {
    //   const transrformeredList = Object.keys(uploadingFilesStatus).map((key) => {
    //     // @ts-ignore
    //     return uploadingFilesStatus[key];
    //   });

    //   return <FilesList onFileDelete={handleRemoveFile} files={transrformeredList} />;
    // }

    return null;
  };

  const renderPlaceholder = () => {
    // if (uploadedFilesCount < MAX_FILES_COUNT) {
    if (value.length < MAX_FILES_COUNT) {
      return <p tw='font-body text-grey-light text-sm font-normal mt-0.5'>{placeholder}</p>;
    }
  };

  const renderError = () => {
    console.log(value)
    if (error || value.length > 5) {
      return <div tw='text-sm text-red-100'>{error || 'Максимален брой позволени файлове: 5.'}</div>;
    }

    // if (fileSizeError) {
    //   return <div tw='text-red-500'>{MAX_FILES_SIZE_ERROR_MESSAGE}</div>;
    // }
  };

  console.log(value);

  console.log('Error: ' + JSON.stringify(error));
  return (
    <div css={[tw`flex flex-wrap justify-start`, isFilesExisted && tw`gap-5`]}>
      <div className='flex overflow-x-auto'>{renderFilesView()}</div>
      {value.length <= 4 && (
        <div
          {...getRootProps()}
          css={[
            tw`flex border-grey-light items-center w-32 cursor-pointer box-border h-32 relative border rounded-md`,
            // isFilesUploaded &&
            // isFilesExisted && tw`mobile:(ml-0) tablet:(ml-5) laptop:(ml-5)`,
            error && tw`border-red-500`,
          ]}
        >
          <input {...getInputProps()} />
          <DefaultView />
        </div>
      )}
      {/* {renderPlaceholder()} */}

      <div className='ml-2'>{renderError()}</div>
    </div>
  );
};

export default FileUploaderField;
