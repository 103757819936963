import tw from 'twin.macro';
import GrayBackgroundWrapper from '../layout/GrayBackgroundWrapper';
import WhiteBoxWrapper from '../layout/WhiteBoxWrapper';
import Button from '../Button/Button';
import ButtonWrapper from '../Button/ButtonWrapper';
import RightArrowModernIcon from '../icons/RightArrowModernIcon';
import { Fragment, useRef, useState } from 'react';
import PdfComponent from '../common/PdfComponent';

import TooltipInfoIcon from '../icons/TooltipIcon';
import { opinionAgreementRadioValues } from '../../types/Form';
import RadioField from '../form/RadioField';
import TextareaField from '../form/TextareaField';
import GrayBoxWrapper from '../layout/GrayBoxWrapper';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import ArrowDownIcon from '../icons/ArrowDownIcon';

import { formatDateInRecommmendedType, getResponseTime } from '../../utils/claimItemHelpers';
import moment from 'moment';
import { updateExternalClaim } from '../../services/actions';
import {
  getLastClientResponseHistoryElement_time,
  getLastHistoryElement_time,
  isClaimInFirstLevel,
  isClaimReceivedSecondCompensation,
  structureLastClientResponseData,
} from '../../utils/helpersFromClaimHistory';
import GiveYourOpinionModal from '../common/GiveYourOpinionModal';
import AlertPopup from '../layout/AlertPopup';
import { isMobile, isTablet } from 'react-device-detect';
import useAuthRedirect from '../../hooks/use-auth-redirect-hook';
import routePaths from '../../route-paths';
import useQuery from '../../hooks/use-query-hook';
import DownloadIcon from '../icons/DownloadIcon';
import OpenIcon from '../icons/OpenIcon';
import axios from 'axios';

interface Props {
  number?: string;
  data: any;
  status?: string;
  active: number;
  feedback?: object | null;
  parameters?: any;
  refetchData: Function;
}

const ClaimsView = ({ data, active, parameters, refetchData }: Props) => {
  const dataLink: any = useRef();
  const [showChronology, setShowChronology] = useState(false);
  const [opinion, setOpinion] = useState('');
  const [opinionMessage, setOpinionMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  const { onRedirect } = useAuthRedirect();

  const queryParameters = useQuery();

  const skipAuth = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2
  );

  const skipAuthFullParameters = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2 &&
    (queryParameters.skipNumbers ||
      queryParameters.awbNumbers ||
      queryParameters.moneyTransferNumbers) &&
    // (queryParameters.idClient || queryParameters.Clients) &&
    queryParameters.time
  );

  const openInNewTab = (url: string) => {
    axios.get(url).then((res) => {
      console.log(res);
    });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { type, name, checked, value } = e.target;

    switch (name) {
      case 'opinion':
        return setOpinion(value);
      case 'opinionMessage':
        return setOpinionMessage(value);
      default:
        return;
    }
  };

  const renderDeadline = () => {
    const { time, tooltipText, expired } = getResponseTime(
      data.compensationStatus,
      data.deadlineDays,
      data.deadlineDays
    );

    return (
      <WhiteBoxWrapper className='p-5'>
        <div tw='flex gap-2 justify-between w-full flex justify-between mobile:(flex-wrap) tablet:(flex-wrap) laptop:(flex-nowrap)'>
          <div
            tw='flex items-center font-light mobile:(flex-wrap) tablet:(flex-wrap) laptop:(flex-nowrap)'
            style={{ color: expired ? '#e41e1a' : '' }}
          >
            <span tw='font-medium text-sm'>Ще ви отговорим до</span>
            <span className='font-bold text-sm flex items-center mobile:(flex-col-reverse) laptop:(flex-row)'>
              {tooltipText ? (
                <>
                  <span tw='mr-1 ml-1'>
                    <TooltipInfoIcon tooltipText={tooltipText} />
                  </span>
                  <span>{time} </span>
                </>
              ) : (
                <span tw='ml-1'>{time} </span>
              )}
            </span>
          </div>

          <ButtonWrapper>
            <button
              type='button'
              tw='text-blue-500 font-light font-body flex items-center text-sm'
              onClick={() => openInNewTab('https://www.econt.com/econt-express/common-terms')}
            >
              Общи условия за услугите на Еконт{' '}
              <RightArrowModernIcon
                width='16'
                height='16'
                fill='rgba(72,119,206)'
                className='ml-2'
              />
            </button>
          </ButtonWrapper>
        </div>
      </WhiteBoxWrapper>
    );
  };

  const renderClaim = () => {
    const labelsData = data.label?.split(', ');

    const getImageSrc = (fileUrl: any) => {
      if (fileUrl) {
        console.log(fileUrl);
        if (fileUrl.includes('pdf')) return `${process.env.PUBLIC_URL}/images/pdf.svg`;
        if (fileUrl.includes('mp4') || fileUrl.includes('mov') || fileUrl.includes('quicktime'))
          return `${process.env.PUBLIC_URL}/images/video.svg`;
        if (fileUrl.includes('msword') || fileUrl.includes('wordprocess'))
          return `${process.env.PUBLIC_URL}/images/document.svg`;
        if (fileUrl.includes('excel') || fileUrl.includes('spreadsheetml'))
          return `${process.env.PUBLIC_URL}/images/xlsx.svg`;

        if (fileUrl.includes('jpeg')) return fileUrl + '/download_file/pic.jpeg';
        if (fileUrl.includes('png')) return fileUrl + '/download_file/pic.png';
        if (fileUrl.includes('jpg')) return fileUrl + '/download_file/pic.jpg';
        if (fileUrl.includes('webp')) return fileUrl + '/download_file/pic.webp';

        return fileUrl + '/download_file';
      } else return '';
    };

    const getFileName = (fileUrl: any) => {
      if (fileUrl) {
        if (fileUrl.includes('pdf')) return `download_file.pdf`;
        if (fileUrl.includes('mp4')) return `download_file.mp4`;
        if (fileUrl.includes('mov') || fileUrl.includes('quicktime')) return `download_file.mov`;
        if (fileUrl.includes('msword') || fileUrl.includes('wordprocess'))
          return `download_file.docx`;
        if (fileUrl.includes('ms-excel')) return `download_file.xls`;
        if (fileUrl.includes('spreadsheetml')) return `download_file.xlsx`;
        if (fileUrl.includes('jpeg')) return `pic.jpeg`;
        if (fileUrl.includes('jpg')) return `pic.jpg`;
        if (fileUrl.includes('png')) return `pic.png`;
        if (fileUrl.includes('webp')) return `pic.webp`;

        return fileUrl + '/download_file';
      } else return 'download_file';
    };

    return (
      <Fragment>
        <div tw='flex justify-between mobile:(flex-col gap-3) laptop:(flex-row)'>
          <div tw='text-sm mobile:w-full tablet:w-9/12'>
            {labelsData?.map((item: any, index: any) => (
              <span className='font-bold' key={item}>
                {item}
                {labelsData.length - 1 !== index ? ', ' : ''}
              </span>
            ))}
            {data.shipmentNumbers && (
              <span tw='font-normal text-sm leading-5'>: {data.shipmentNumbers}</span>
            )}
          </div>
          <span tw='font-light flex justify-between mobile:(flex-wrap text-[13px]) laptop:(flex-nowrap text-[13px]) flex-row'>
            <b>{formatDateInRecommmendedType(data.date)}</b>
          </span>
        </div>
        {data.note && (
          <div className='mt-2 min-h-20 max-h-52 overflow-y-scroll no-scrollbar text-gray-600 text-sm'>
            {data.note.split('\\n').map((str: string, index: number) => {
              if (!str)
                return (
                  <p key={index}>
                    <br />
                  </p>
                );
              return <p key={index}>{str}</p>;
            })}
          </div>
        )}
        <div tw='flex gap-4 flex-wrap'>
          {data.paths.map((fileUrl: any, i: any) => (
            <div key={i}>
              <img
                key={i}
                width={125}
                height={125}
                src={getImageSrc(fileUrl)} //fileUrl
                alt={fileUrl + i}
                tw='rounded-lg mt-5 cursor-pointer w-[125px] h-[7.8rem]'
                onClick={() => openInNewTab(fileUrl + '/download_file/' + getFileName(fileUrl))}
              />
              {/* {!isMobile && !isTablet && (
                <a
                  href={fileUrl + '/download'}
                  download
                  tw='text-blue-600 font-light font-body flex'
                  ref={dataLink}
                >
                  Изтегли
                  <DownloadIcon width='16' height='16' className='ml-2' fill='rgb(37 99 235)' />
                </a>
              )}
              <a
                href={fileUrl + '/download_file'}
                target='_blank'
                rel='noreferrer'
                tw='text-blue-600 font-light font-body flex'
              >
                Отвори <OpenIcon width='16' height='16' className='ml-2' fill='rgb(37 99 235)' />
              </a> */}
            </div>
          ))}
        </div>
      </Fragment>
    );
  };

  const renderClaimInPendingFields = () => {
    if (active === 1)
      return (
        <>
          {renderDeadline()}
          <WhiteBoxWrapper className='p-5 mt-5'>
            <div>
              {!data.offerCode && data.decisionFileUrl && renderEcontResponse()}
              {!isClaimInFirstLevel(data.history) &&
                !data.receivedButWithoutDecisionFileUrl &&
                renderLastClientResponse()}
              {(isClaimInFirstLevel(data.history) || data.receivedButWithoutDecisionFileUrl) &&
                renderClaim()}
            </div>
          </WhiteBoxWrapper>
        </>
      );
    else return null;
  };

  const handleSendResponse = async () => {
    console.log('...');
    if (isClaimReceivedSecondCompensation(data.history)) {
      onRedirect(routePaths.payOnline, { id: data.number }, { ...queryParameters });
      // if (data.offerCode === '0103' || data.offerCode === '0107')
      //   onRedirect(routePaths.payOnline, { id: data.number }, { ...queryParameters });
    } else if (
      opinion === '1' &&
      (data.offerCode === '0103' ||
        data.offerCode === '0104' ||
        data.offerCode === '0107')
    ) {
      onRedirect(routePaths.payOnline, { id: data.number }, { ...queryParameters });
    } else {
      const bodyData =
        opinion === '1'
          ? {
              reclamationNum: data.number,
              clientAgreeWithDecision: true,
            }
          : {
              reclamationNum: data.number,
              clientAgreeWithDecision: false,
              clientResponseToDecision: opinionMessage,
            };
      const res = await updateExternalClaim(
        bodyData,
        parameters,
        skipAuth || skipAuthFullParameters
      );
      if (res && res.error) {
        setShowError(true);
        setError(res.error);
      } else {
        refetchData();
      }
    }
  };

  const renderLastClientResponse = () => {
    const { title, line1, line2, line3 } = structureLastClientResponseData(
      data.history,
      data.paymentOptions,
      data.compensationAmount
    );

    return (
      <div>
        <p tw='flex justify-between mobile:(flex-wrap) tablet:(flex-wrap) laptop:(flex-nowrap)'>
          <span tw='text-sm'>
            <b>{title}</b>
          </span>
          <span tw='text-[13px]'>
            {formatDateInRecommmendedType(getLastClientResponseHistoryElement_time(data.history))}
          </span>
        </p>
        <ul tw='list-disc ml-4 flex gap-2 flex-col mt-2'>
          {line1 && <li tw='text-sm'>{line1}</li>}
          {line2 && <li tw='text-sm'>{line2}</li>}
          {line3 && <li tw='text-sm'>{line3}</li>}
        </ul>
      </div>
    );
  };

  const renderEcontResponse = () => {
    return (
      <Fragment>
        <div tw='flex justify-between mobile:(flex-col-reverse) laptop:(flex-row)'>
          <p className='text-gray-700 text-sm'>Здравейте,</p>
          <span tw='font-light text-sm mobile:(mb-5) laptop:(mb-0)'>
            <span tw='text-[13px]'>
              <b>{formatDateInRecommmendedType(getLastHistoryElement_time(data.history))}</b>
            </span>
          </span>
        </div>
        {data.decisionFileUrl && (
          <div className='text-gray-700 mt-2'>
            <p className='mt-5 text-sm'>
              Приложено ще откриете становище по случая. Ако имате въпроси, можете да разгледате
            </p>
            <button
              type='button'
              tw='text-blue-500 font-light bg-transparent font-body flex items-center text-sm'
              onClick={() => openInNewTab('https://www.econt.com/econt-express/common-terms')}
            >
              Общи условия за услугите на Еконт{' '}
              <RightArrowModernIcon
                width='16'
                height='16'
                fill='rgba(72,119,206)'
                className='ml-2'
              />
            </button>
            <PdfComponent
              customDocument={data.decisionFileUrl}
              claimNum={data.number}
              className='mt-5'
            />
          </div>
        )}
      </Fragment>
    );
  };

  const renderOpinionToEcontResponse = () => {
    const { time, tooltipText, expired, text } = getResponseTime(
      data.compensationStatus,
      data.deadlineDays,
      data.deadlineDays
    );

    console.log(expired, 'expC');
    return (
      <WhiteBoxWrapper className='mt-1 p-5'>
        <Fragment>
          <div tw='flex justify-between items-center mobile:(flex-wrap) tablet:(flex-wrap) laptop:(flex-nowrap)'>
            <div tw='flex mobile:flex-col laptop:flex-row gap-1'>
              <span tw='font-light text-lg'>Статус :</span>
              <span tw='font-bold text-lg flex flex-wrap'>
                {(data.offerCode === '0103' ||
                  data.offerCode === '0104' ||
                  data.offerCode === '0107' ||
                  data.offerCode === '105' ||
                  data.offerCode === '0101') && (
                  <Fragment>
                    <span>{data.offerDescription}&nbsp;</span>
                    {/* ?.replace('.', ',') */}
                    <span tw='flex flex-nowrap'>{data.compensationAmount + ' лв.'}</span>
                  </Fragment>
                )}
                {data.offerCode !== '0103' &&
                  data.offerCode !== '0104' &&
                  data.offerCode !== '0107' &&
                  data.offerCode !== '105' &&
                  data.offerCode !== '0101' &&
                  data.offerDescription}
              </span>
            </div>
            <div tw='text-sm flex mobile:mt-4 laptop:mt-0'>
              <span
                tw='flex gap-1 text-sm font-light mobile:(flex-wrap) tablet:(flex-wrap) laptop:(flex-nowrap)'
                style={{ color: expired ? '#e41e1a' : '' }}
              >
                <span tw='text-sm text-gray-700'>{text}</span>
                <span tw='font-bold text-sm flex'>
                  <span className='mr-2'>{time}</span>{' '}
                  {tooltipText && <TooltipInfoIcon tooltipText={tooltipText} />}
                </span>
              </span>
            </div>
          </div>
          <div>
            {!isClaimReceivedSecondCompensation(data.history) && !expired && (
              <Fragment>
                <div tw='flex justify-center gap-5 mt-5 mobile:(flex-wrap) tablet:(flex-wrap) laptop:(flex-nowrap)'>
                  {opinionAgreementRadioValues.map((radio, index) => (
                    <RadioField
                      key={index}
                      name={'opinion'}
                      label={radio.label}
                      value={radio.value}
                      checked={opinion === radio.value}
                      error={''}
                      onChange={handleInputChange}
                      className={`flex flex-row-reverse border border-gray-400 rounded-lg cursor-pointer w-full items-center ${
                        opinion === radio.value && 'border-blue-700 bg-blue-50'
                      } input-radio-blue-hover`}
                      classNameInput={`mr-2 cursor-pointer border-2 border-blue-600 input-icon-tick input-icon-tick`}
                      classNameLabel='w-full h-full p-5 text-center cursor-pointer text-blue-800'
                    />
                  ))}
                </div>
                <div className='my-5'>
                  {opinion && opinion === '2' && (
                    <TextareaField
                      name={'opinionMessage'}
                      placeholder={'Мнение...'}
                      value={opinionMessage}
                      large
                      error={''}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              </Fragment>
            )}
            <ButtonWrapper>
              {isClaimReceivedSecondCompensation(data.history) && (!expired as boolean) ? (
                <div className='mt-5'>
                  <Button
                    disabled={expired as boolean}
                    type={'button'}
                    variant={'mini'}
                    onClick={handleSendResponse}
                    width={isMobile || isTablet ? '100%' : ''}
                  >
                    Приеми обезщетение
                  </Button>
                </div>
              ) : !expired ? (
                <Button
                  disabled={opinion === '' || (expired as unknown as boolean)}
                  type={'button'}
                  variant={isMobile || isTablet ? 'mini' : 'compact'}
                  onClick={handleSendResponse}
                >
                  Изпрати отговор
                </Button>
              ) : (
                <div></div>
              )}
            </ButtonWrapper>
          </div>
        </Fragment>
      </WhiteBoxWrapper>
    );
  };

  const renderFinalEcontNoPaymentDecision = () => {
    return (
      <WhiteBoxWrapper className='mt-1 p-5'>
        <Fragment>
          <div className='flex justify-between'>
            <span className='font-light text-sm'>
              Становище:
              <span className='font-bold ml-1'>
                {data.offerCode === '0103' ||
                data.offerCode === '0104' ||
                data.offerCode === '0107' ||
                data.offerCode === '105' ||
                data.offerCode === '0101'
                  ? data.offerDescription + ' ' + data.compensationAmount + ' лв.'
                  : data.offerDescription}
                  {/* ?.replace('.', ',') */}
              </span>
            </span>
          </div>
        </Fragment>
      </WhiteBoxWrapper>
    );
  };

  const renderResponseReceived = () => {
    if (active === 2)
      return (
        <>
          <WhiteBoxWrapper className='p-5 mt-5'>{renderEcontResponse()}</WhiteBoxWrapper>
          {renderOpinionToEcontResponse()}
        </>
      );
  };

  const renderChronology = () => {
    const arrowSize = '18';
    if (data.history)
      return (
        <Fragment>
          <div
            className='flex items-center mt-10 cursor-pointer whitespace-nowrap gap-2'
            onClick={() => setShowChronology(!showChronology)}
          >
            <hr tw='w-full border-t border-gray-300' />

            <span tw='mobile:(text-sm) laptop:(text-[0.8rem]) mb-1'>
              {showChronology ? 'Виж по-малко' : `Хронология (${data.history.length + 1})`}
            </span>
            <div>
              {showChronology ? (
                <ArrowUpIcon width={arrowSize} height={arrowSize} fill='rgba(72,119,206)' />
              ) : (
                <ArrowDownIcon width={arrowSize} height={arrowSize} fill='rgba(72,119,206)' />
              )}
            </div>

            <hr tw='w-full border-t border-gray-300' />
          </div>
          <div className='relative'>
            <div className={showChronology ? '' : 'css-blurry-gradient'}></div>
            {/* <GrayBoxWrapper className='mt-5 p-5'>{renderEcontResponse()}</GrayBoxWrapper> */}

            <div className={!showChronology ? 'h-72' : ''}>
              {data.history.map((log: any) => {
                return (
                  <GrayBoxWrapper key={log.time + Math.random()} className='mt-5 p-5'>
                    <div tw='flex justify-between mobile:(flex-col) laptop:(flex-row)'>
                      <div>
                        <span tw='text-sm overflow-x-auto w-full'>
                          {log.field}:
                          <b tw='ml-1'>
                            {log.value
                              ? log.value
                              : log.files.map((file: any) => (
                                  <a
                                    key={file.name}
                                    href={file.url}
                                    download
                                    target='_blank'
                                    rel='noreferrer'
                                    tw='text-sm break-words'
                                  >
                                    {file.name}
                                  </a>
                                ))}
                          </b>
                        </span>
                      </div>
                      <div tw='text-[13px]'>
                        {formatDateInRecommmendedType(moment(log.time).format('DD/MM/YYYY'))}
                      </div>
                    </div>
                  </GrayBoxWrapper>
                );
              })}
              <GrayBoxWrapper className='mt-5 p-5'>{renderClaim()}</GrayBoxWrapper>
            </div>
          </div>
        </Fragment>
      );
  };

  const renderFinalDecision = () => {
    if (active === 3)
      return (
        <div>
          <GiveYourOpinionModal
            number={data?.number}
            selected={data.feedback}
            refetchData={refetchData}
          />
          {data.paymentOptions &&
            (data.paymentOptions.type === 'bank' ||
              data.paymentOptions.type === 'office' ||
              data.paymentOptions.type === 'ecoints') && (
              <Fragment>
                <WhiteBoxWrapper className='mt-1 p-5'>
                  <div tw='flex mobile:(flex-col-reverse)'>
                    <li className='pr-5 ml-4 mt-2 list-disc text-sm'>
                      {formatDateInRecommmendedType(getLastHistoryElement_time(data.history))}
                    </li>
                    <span tw='text-sm'>
                           {/* ?.replace('.', ',') */}
                      Финален статус: <b>Ще изплатим обезщетение {data.compensationAmount} лв. </b>
                    </span>
                  </div>
                </WhiteBoxWrapper>
                <WhiteBoxWrapper className='mt-1 p-5'>{renderLastClientResponse()}</WhiteBoxWrapper>
              </Fragment>
            )}
          {!data.paymentOptions?.type && data.offerCode !== '0103' && (
            <Fragment>
              <WhiteBoxWrapper className='mt-1 p-5'>
                <Fragment>{renderEcontResponse()}</Fragment>
              </WhiteBoxWrapper>
              {renderFinalEcontNoPaymentDecision()}
            </Fragment>
          )}
        </div>
      );
  };

  if (showError) {
    return (
      <AlertPopup
        show={showError}
        text='Неуспешeн ъпдейт по рекламация'
        subText={error}
        onClick={() => {
          setError('');
          setShowError(false);
        }}
        onClose={() => {
          setError('');
          setShowError(false);
        }}
      />
    );
  }

  return (
    <div tw='font-body overflow-hidden'>
      <GrayBackgroundWrapper top={'0.1rem'} />
      <div className={`${isMobile || isTablet ? 'mt-14' : 'mt-10'}`}>
        {renderClaimInPendingFields()}
        {renderResponseReceived()}
        {renderFinalDecision()}
      </div>
      <div>{data?.history?.length > 1 ? renderChronology() : ''}</div>
    </div>
  );
};

export default ClaimsView;
