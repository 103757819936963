import tw, { css } from 'twin.macro';
import useAuthRedirect from '../../../hooks/use-auth-redirect-hook';
import routePaths from '../../../route-paths';
import useQuery from '../../../hooks/use-query-hook';

import ProfileDropdown from './ProfileDropdown';
import MobileNavigationDropdown from './MobileNavigationDropdown';

import LogoIcon from '../../icons/LogoIcon';
import React from 'react';

const LinkItem = tw.a`text-white text-lg hover:text-grey-900`;
const ProfileNavLink = tw.div`text-white text-lg ml-8 font-body`;
const ProfileNavLinkMobile = tw(ProfileNavLink)`absolute mobile:(text-base bottom-3) tablet:hidden`;

const HeightWrapper = tw.div`mobile:h-22 tablet:h-15 bg-black-light`;
const FixedWrapper = tw(
  HeightWrapper
)`px-5 z-10 mobile:(w-full fixed top-0 left-0) tablet:relative `;

const Header = () => {
  const { onRedirect } = useAuthRedirect();
  const queryParameters = useQuery();

  const handleLogoRedirect = () => {
    onRedirect(routePaths.root, queryParameters, queryParameters);
  };

  const renderMobileNav = () => {
    return (
      <React.Fragment>
        <ProfileNavLinkMobile tw='right-10'>
          <ProfileDropdown />
        </ProfileNavLinkMobile>
        <ProfileNavLinkMobile tw='right-0 bottom-5'>
          <MobileNavigationDropdown />
        </ProfileNavLinkMobile>
      </React.Fragment>
    );
  };

  const renderLaptopNav = () => {
    return (
      <div
        css={css`
          min-width: 480px;
          ${tw`tablet:flex mobile:hidden justify-end items-center`}
        `}
      >
        <LinkItem href='https://econt.com' target='a_blank' tw='text-white text-base ml-8 font-body'>
          econt.com
        </LinkItem>
        <LinkItem
          href='https://ee.econt.com'
          target='a_blank'
          tw='text-white text-base ml-8 font-body'
        >
          e-Econt
        </LinkItem>
        {/* <div tw='ml-8'>
              <LanguageDropdown />
            </div> */}
        <div tw='ml-4'>
          <ProfileDropdown />
        </div>
      </div>
    );
  };

  return (
    <HeightWrapper
      id='header-id'
      css={css`
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 9999;
        display: ${localStorage.getItem('hideHeader') ? 'none' : 'block'};
      `}
    >
      <FixedWrapper
        css={css`
          width: 100%;
        `}
      >
        <div tw='mobile:(justify-between items-end h-full) tablet:(justify-between items-center h-15) laptop:(px-20) relative w-full flex'>
          <div>
            <span
              onClick={handleLogoRedirect}
              tw='mobile:(flex items-center mb-5) flex-nowrap tablet:mb-0 cursor-pointer'
            >
              <LogoIcon className='mobile:w-19 tablet:w-full' />
              <span tw='text-sm text-white  ml-2 font-body'>Рекламации</span>
            </span>
          </div>
          {renderMobileNav()}
          {renderLaptopNav()}
        </div>
      </FixedWrapper>
    </HeightWrapper>
  );
};

export default Header;
