import { isMobile, isTablet } from 'react-device-detect';
import tw from 'twin.macro';
import useAuthRedirect from '../../../hooks/use-auth-redirect-hook';
import useQuery from '../../../hooks/use-query-hook';
import routePaths from '../../../route-paths';
import Button from '../../Button/Button';
import ButtonWrapper from '../../Button/ButtonWrapper';
import TabElement from './TabElement';
import { TabHead } from './types';

interface Props {
  currentTab: number;
  onTabChange: (newTab: number) => void;
  tabs: TabHead[];
  totalResults?: number;
}

const TabsHeader = ({ currentTab, onTabChange, tabs, totalResults }: Props) => {
  const { onRedirect } = useAuthRedirect();
  const queryParameters = useQuery();

  const createNewClaim = () => {
    onRedirect(
      routePaths.root,
      { ...queryParameters, skipNumbers: 'true' },
      { ...queryParameters, skipNumbers: 'true' }
    );
  };

  return (
    <div
      className={`fixed top-0 left-0 right-0 z-[1000000] bg-white flex justify-center ${
        localStorage.getItem('hideHeader') ? 'mt-0' : 'mt-14'
      }`}
    >
      <div tw='mobile:(px-5) laptop:(px-0 max-w-lg) mt-5 w-full flex flex-col justify-between'>
        <div
          css={[
            tw`flex flex-row justify-between`,
            tw`text-black-light font-normal font-body`,
            tw`mobile:(mb-1 flex flex-col) tablet:(mb-1 flex flex-row)`,
          ]}
        >
          <span tw='text-2xl font-light'>{totalResults} рекламации</span>
          <div tw='mobile:hidden laptop:flex'>
            <ButtonWrapper>
              <Button type={'button'} variant={'compact'} onClick={createNewClaim}>
                Нова рекламация
              </Button>
            </ButtonWrapper>
          </div>
        </div>

        <div className={`flex gap-3 mt-4 font-body tablet:(ml-5)`}>
          {tabs.map((item: any, index) => (
            <TabElement
              key={item.index}
              index={item.index}
              isFirst={index === 0}
              isSelected={item.index === currentTab}
              onClick={onTabChange}
              label={item.label}
              disabled={item.disabled}
              showEcointsIcon={item.showEcointsIcon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabsHeader;
