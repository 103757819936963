import LeftArrow from '../icons/LeftArrow';
import tw from 'twin.macro';
import LocationIcon from '../icons/LocationIcon';
interface Props {
  showMap: boolean;
  setShowMap: Function;
  setOffice: Function;
  activatorText: string;
}

const OfficeLocatorMobile = ({ activatorText, showMap, setShowMap, setOffice }: Props) => {
  window.addEventListener('message', (e) => {
    setOffice(e.data?.office);
    setShowMap(false);
  });

  const handleBack = () => {
    setShowMap(false);
  };

  return (
    <>
      {showMap ? (
        <div>
          {localStorage.getItem('hideHeader') ? (
            <div
              tw='fixed top-0 h-[48px] bottom-0 left-0 right-0 w-full m-0 p-0 bg-white flex items-center text-center px-4 cursor-pointer'
              onClick={handleBack}
            >
              <LeftArrow
                width='20'
                height='20'
                viewbox='0 0 16 16'
                fill='black'
                tw='absolute font-bold'
              />
              <span tw='w-full font-light text-xl'>Избери офис</span>
            </div>
          ) : (
            <div
              tw='fixed top-[64px] h-[48px] bottom-0 left-0 right-0 w-full m-0 p-0 bg-white flex items-center text-center px-4 cursor-pointer'
              onClick={handleBack}
            >
              <LeftArrow width='24' height='24' fill='black' tw='absolute font-bold' />
              <span tw='w-full font-light text-xl'>Избери офис</span>
            </div>
          )}
          <iframe
            title='Locator'
            scrolling='no'
            frameBorder='0'
            style={{
              position: 'fixed',
              top: localStorage.getItem('hideHeader') ? '48px' : '112px',
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: localStorage.getItem('hideHeader')
                ? 'calc(100% - 48px)'
                : 'calc(100% - 112px)',
              border: 'none',
              margin: 0,
              padding: 0,
              zIndex: 22332,
              overflow: 'hidden',
            }}
            allowFullScreen
            allow='geolocation'
            src='https://officelocator.econt.com/?shopUrl=https://example.officelocator.econt.com&filterOut=ekontomat'
          ></iframe>
        </div>
      ) : (
        <div onClick={() => setShowMap(true)} tw='cursor-pointer'>
          <span
            tw='text-[13px] text-blue-100 text-center flex items-center flex ml-[1.6rem]'
            style={{ color: '#4877CE' }}
          >
            {activatorText} <LocationIcon />{' '}
          </span>
        </div>
      )}
    </>
  );
};

export default OfficeLocatorMobile;
