import { isMobile, isTablet } from 'react-device-detect';
import { css, styled } from 'twin.macro';
import ReactModal from 'react-modal';
import XIcon from '../icons/XIcon';
import EcointsLogo from '../icons/EcointsLogo';
import HrefArrow from '../icons/HrefArrow';

export interface Props {
  open: boolean;
  setOpen: any;
}

const modalStyle = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.486)',
    zIndex: 100024234232342342,
  },
  content: {
    border: 'none',
    borderRadius: '12px',
    top: '50%',
    left: '50%',
    minWidth: '320px',
    maxWidth: '500px',
    height: isMobile || isTablet ? '80%' : '320px',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalEcointsDIgitalWallet = ({ open, setOpen }: Props) => {
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      contentLabel='My dialog'
      style={modalStyle}
    >
      <div className='p-0 flex flex-col justify-between h-full'>
        <div>
          <div className='w-full flex justify-end'>
            <XIcon
              tw='cursor-pointer'
              fill='#212121'
              width='16'
              height='16'
              onClick={() => setOpen(false)}
            />
          </div>
          <div>
            <EcointsLogo width='103' height='34' rectWidth='30' rectHeight='30' />
          </div>
          <h1
            className='mt-4'
            tw='text-lg'
            style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}
          >
            Ползи за теб с дигиталния портфейл на Еконт
          </h1>
          <div className='mt-2'>
            <p tw='text-[13px]' style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}>
              • Плащаш с известие или QR код куриерски си услуги и получаваш част от платената сума
            </p>
            <p tw='text-[13px]' style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}>
              • Плащаш удобно битовите си сметки към над 650 доставчици
            </p>
            <p tw='text-[13px]' style={{ fontFamily: 'Open Sans, Regular', color: '#343434' }}>
              • Пращаш или искаш пари от приятел за секунди в ecoints.
            </p>
          </div>
          <div tw='flex items-center mt-2'>
            <span tw='text-[13px]' style={{ fontFamily: 'Open Sans, Regular', color: '#212121' }}>
              Научи повече на
            </span>
            <a
              tw='flex items-center ml-1 gap-2 text-[13px]'
              style={{ fontFamily: 'Open Sans, Regular', color: '#4877CE' }}
              href='https://ecoints.com'
              target='_blank'
              rel='noreferrer'
            >
              ecoints.com <HrefArrow fill='#4877CE' width='12' height='12' />
            </a>
          </div>
        </div>
        <div className='flex flex-row flex-wrap justify-between'>
          <div className='flex flex-row flex-wrap gap-2'>
            <img
              width={90}
              height={40}
              src={`${process.env.PUBLIC_URL}/images/app_store.svg`}
              alt='ecoints-toast'
            />
            <img
              width={90}
              height={40}
              src={`${process.env.PUBLIC_URL}/images/huawei.svg`}
              alt='ecoints-toast'
            />
            <img
              width={90}
              height={40}
              src={`${process.env.PUBLIC_URL}/images/google_play.svg`}
              alt='ecoints-toast'
            />
          </div>
          <div className='flex flex-row justify-center items-center'>
            <div className='flex flex-col'>
              <span tw='text-[10px]' style={{ fontFamily: 'Open Sans, Regular', color: '#616161' }}>
                Сканирай
              </span>
              <span tw='text-[10px]' style={{ fontFamily: 'Open Sans, Regular', color: '#616161' }}>
                и изтегли
              </span>
            </div>
            <div>
              <img
                width={50}
                height={50}
                src={`${process.env.PUBLIC_URL}/images/qr_ecoints.png`}
                alt='ecoints-toast'
              />
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalEcointsDIgitalWallet;
