import Button from '../Button/Button';
import ButtonWrapper from '../Button/ButtonWrapper';
import TextDocIcon from '../icons/TextDocIcon';
import GrayBackgroundWrapper from '../layout/GrayBackgroundWrapper';
import Wrapper from '../layout/Wrapper';

interface Props {
  title?: string;
  onClick: Function;
  className?: string;
}

const FirstClaimBox = ({ title, onClick, className }: Props) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <>
      <GrayBackgroundWrapper top='0px' />
      <div style={{ height: '70vh' }} className={`flex justify-center ${className || 'items-center'}`}>
        <div
          style={{ background: 'white' }}
          className='flex justify-center items-center text-center py-10 flex-col rounded-lg max-w-md'
        >
          <TextDocIcon className=' mb-6' />
          <h1 className='text-2xl text-gray-900'>Желаете да подадете рекламация?</h1>
          <span className='text-gray-600 mt-4 w-9/12 mb-10'>
            Можете да ни сигнализирате тук, ако пратката ви липсва, непълна е, повредена е или
            доставката й се е забавила.
          </span>
          <ButtonWrapper>
            <Button type={'button'} variant={'default'} onClick={handleClick}>
              Подай рекламация
            </Button>
          </ButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default FirstClaimBox;
