import tw, { css } from 'twin.macro';
import Footer from './Footer';
import Header from './Header';

interface Props {
  children: JSX.Element;
}

const Wrapper = ({ children }: Props) => {
  return (
    <div tw='min-h-screen'>
      <Header />
      <div
        css={[
          tw`p-5`,
          css`
            min-height: calc(100vh - 3.75rem);

            @media (max-width: 768px) {
              min-height: 100vh;
            }
          `,
          // min-height: calc(100vh - 3.75rem) where 3.75rem - footer height in tablet resolution
        ]}
      >
        <div tw='mx-auto laptop:max-w-lg h-full'>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Wrapper;
