import moment from 'moment';
import DoneClaimIcon from '../components/icons/DoneClaimIcon';
import EcontomatIcon from '../components/icons/Econtomat';
import OfficeIcon from '../components/icons/Office';
import PendingIcon from '../components/icons/PendingIcon';
import ReceivedIcon from '../components/icons/ReceivedIcon';
import XIcon from '../components/icons/XIcon';

import { Status } from '../types/Data';
import { getDaysRemaining } from './dataFormatter';

export const getStatusIconClaimItem = (status: Status) => {
  switch (status) {
    case Status.Pending:
      return PendingIcon;
    case Status.Received:
      return ReceivedIcon;
    case Status.Reject:
      return XIcon;
    case Status.DoneUnpayed:
    case Status.Done:
      return DoneClaimIcon;
    default:
      return PendingIcon;
  }
};

export const getOfficeIcon = (officeName: string) => {
  if (officeName) {
    if (officeName.includes('еконтомат') || officeName.includes('Еконтомат')) return EcontomatIcon;
    else return OfficeIcon;
  } else return OfficeIcon;
};

export const getStatusTitleClaimItem = (status: Status) => {
  console.log(status)
  switch (status) {
    case Status.Pending:
      return {
        color: 'rgb(75 85 99)',
        text: 'Обработваме рекламацията',
      };
    case Status.Received:
      return {
        color: '#4c74cc',
        text: 'Получихте становище',
      };
    case Status.Done:
      return {
        color: '#339900',
        text: 'Получихте обезщетение',
      };
    case Status.DoneUnpayed:
      return {
        color: '#339900',
        text: 'Без обезщетение',
      };
    case Status.Reject:
      return {
        color: '#e41e1a',
        text: 'Отхвърлена',
      };
    default:
      return {
        color: 'rgb(75 85 99)',
        text: 'Обработваме рекламацията',
      };
  }
};

export const getActiveStep = (status: Status) => {
  switch (status) {
    case Status.Pending:
      return 1;
    case Status.Received:
      return 2;
    case Status.DoneUnpayed:
    case Status.Done:
      return 3;
    default:
      return 1;
  }
};

export const getResponseTime = (status: Status, responseTime: string, finishedAt?: string) => {
  let text = '';
  let tooltipText = '';
  let time: String | Number = '';
  let expired: Boolean = false;

  switch (status) {
    case Status.Pending:
      text = 'Ще ви отговорим до';
      break;
    case Status.Received:
      text = 'Отговорете ни до';
      tooltipText = 'С изтичането на посочения срок, становището автоматично се счита за отказано.';
      break;
    case Status.DoneUnpayed:
    case Status.Done:
      text = 'Приключена на:';
      time = finishedAt || '';
      break;
    default:
      text = 'Ще ви отговорим до';
      break;
  }

  if (status === Status.Done || status === Status.DoneUnpayed)
    return {
      time,
      text,
      done: true,
    };

  let days = getDaysRemaining(responseTime);

  // console.log(days)

  // const workDaysText = days === 1 ? ' работен ден' : ' работни дни';

  // if (days && days > 0) time = Math.abs(days) + workDaysText;

  if (days && days > 0) time = moment(responseTime, 'DD-MM-YYYY').format('DD MMMM, YYYY');
  else if (days === 0) {
    const getExactTime = responseTime.split(' ')[1];
    const splitToHoursAndMinutes = getExactTime.split(':');

    if (
      Number(splitToHoursAndMinutes[0]) < moment().hours() ||
      (Number(splitToHoursAndMinutes[0]) <= moment().hours() &&
        Number(splitToHoursAndMinutes[1]) < moment().minutes())
    ) {
      time = moment(responseTime, 'DD-MM-YYYY').format('DD MMMM, YYYY');
      expired = true;
      tooltipText =
        'Срокът за отговор е изтекъл. Може да се свържете с експерт рекламационно производство през слушалката в рекламацията.';
    } else {
      time = responseTime.split(' ')[1] + ' ч.';
    }
  } else if (days < 0) {
    time = moment(responseTime, 'DD-MM-YYYY').format('DD MMMM, YYYY');
    expired = true;
    tooltipText =
      'Срокът за отговор е изтекъл. Може да се свържете с експерт рекламационно производство през слушалката в рекламацията.';
  }

  return {
    text,
    time,
    expired,
    tooltipText,
  };
};

export const formatDateInRecommmendedType = (dateString: String) => {
  if (!dateString) return;
  let date = dateString.split(' ')?.[0];

  let day = '';
  let month = '';
  let year = '';

  const deconstructDateInParts = date.split('/');

  if (deconstructDateInParts.length !== 3) return date;

  day = deconstructDateInParts[0];

  switch (deconstructDateInParts[1]) {
    case '01':
      month = 'Януари';
      break;
    case '02':
      month = 'Февруари';
      break;
    case '03':
      month = 'Март';
      break;
    case '04':
      month = 'Април';
      break;
    case '05':
      month = 'Май';
      break;
    case '06':
      month = 'Юни';
      break;
    case '07':
      month = 'Юли';
      break;
    case '08':
      month = 'Август';
      break;
    case '09':
      month = 'Септември';
      break;
    case '10':
      month = 'Октомври';
      break;
    case '11':
      month = 'Ноември';
      break;
    case '12':
      month = 'Декември';
      break;
    default:
      month = 'Януари';
      break;
  }

  year = deconstructDateInParts[2];

  const formattedDate = day + ' ' + month + ' ' + year + ' г.';

  return formattedDate;
};
