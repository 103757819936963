import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import DownloadIcon from '../components/icons/DownloadIcon';
import useQuery from '../hooks/use-query-hook';
import { download } from '../services/actions';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { isMobileSafari } from 'react-device-detect';

const DownloadFilePage = () => {
  const dataLink: any = useRef();
  const [originalLink, setOriginalLink] = useState('');
  const [data, setData] = useState('');

  const queryParameters = useQuery();

  const params: any = useParams();

  const isValidUrl = (url: string) => {
    try {
      new window.URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!localStorage.getItem('access_token')) {
        if (params.access) {
          localStorage.setItem('access_token', params.access);
          window.location.reload();
        } else {
          alert('You have no access to that page!');
          window.close();
        }
      }
      const customDocument = params.url
        ?.replaceAll('cusd1', 'http://')
        .replaceAll('cusd2', '/')
        .replaceAll('cusd3', '&')
        .replaceAll('cusd4', '.php')
        .replaceAll('cusd5', '?');

      setOriginalLink(customDocument);
      if (customDocument && isValidUrl(customDocument)) {
        const response = await download(queryParameters, customDocument);
        const image = await fetch(response.data);
        const imageBlob = await image.blob();
        const imageUrlBlob = window.URL.createObjectURL(imageBlob);
        setData(`${imageUrlBlob}`);

        if (isMobileSafari) {
          dataLink?.current?.click();
        }
      }
    };

    fetchData();
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return data && !isMobileSafari ? (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.js'>
      <div>
        {/* <button
          tw='bg-blue-100 text-white p-2 w-full'
          onClick={() => window.open('econtapp://econt')}
        >
          Върни се към приложението
        </button> */}
        {originalLink && originalLink.includes('.pdf') ? (
          <Viewer fileUrl={data} plugins={[defaultLayoutPluginInstance]} />
        ) : (
          <img src={data} alt='document-claims' />
        )}
      </div>
    </Worker>
  ) : (
    <a href={data} className='hidden text-blue-600 font-light font-body flex' ref={dataLink}>
      Изтегли <DownloadIcon width='16' height='16' className='ml-2' fill='rgb(37 99 235)' />
    </a>
  );
};

export default DownloadFilePage;
