interface Props {
  className?: string;
}

function ClockIcon({ className }: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='14'
      fill='none'
      viewBox='0 0 12 14'
      className={className || ''}
    >
      <path
        fillRule='evenodd'
        stroke='rgb(75 85 99)'
        d='M11.334 7.718C11.334 10.635 8.946 13 6 13 3.055 13 .667 10.635.667 7.718S3.055 2.436 6 2.436a5.36 5.36 0 013.772 1.547c1 .99 1.562 2.334 1.562 3.735z'
        clipRule='evenodd'
      ></path>
      <path
        stroke='rgb(75 85 99)'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M5.992 4.354v3.289L9.31 9.775M4.271 1.168l3.516.005M5.992 1.263v.962'
      ></path>
    </svg>
  );
}

export default ClockIcon;
