import moment from 'moment';
import { Status } from '../types/Data';

export const getLastHistoryElement_time = (history: any) => {
  if (history && history.length) return moment(history[0]?.time).format('DD/MM/YYYY');
  else return '';
};

export const getLastHistoryElement_time_to_response = (history: any) => {
  if (history && history.length)
    return moment(history[0]?.time).add(15, 'days').format('DD/MM/YYYY H:mm');
  else return '';
};

export const getLastClientResponseHistoryElement_time = (history: any) => {
  if (history && history.length) {
    const lastHistoryFromClient = history.find((hist: any) =>
      hist.field.toLowerCase().includes('отговор от клиента')
    );
    if (!lastHistoryFromClient || !lastHistoryFromClient.time) return '';
    return moment(lastHistoryFromClient.time).format('DD/MM/YYYY');
  } else return '';
};

export const isClaimWaitingForEcontResponse = (history: any, paymentOptions?: any) => {
  if (history && history.length) {
    if (history[0].field.toLowerCase().includes('отговор от клиента')) return true;
    else return false;
  } else return false;
};

export const isClaimAggreedWithDecisionThroughEcoints = (
  compensationStatus: any,
  isClientAgreeWithDecision: any,
  paymentOptions: any
) => {
  if (paymentOptions) {
    if (
      compensationStatus === Status.Received &&
      isClientAgreeWithDecision &&
      paymentOptions.type === 'ecoints'
    ) {
      return {
        valid: true,
        status: Status.Received,
      };
    } else if (
      (compensationStatus === Status.Done || compensationStatus === Status.DoneUnpayed) &&
      isClientAgreeWithDecision &&
      paymentOptions.type === 'ecoints'
    ) {
      return {
        valid: true,
        status: compensationStatus,
      };
    } else {
      return {
        valid: false,
        status: Status.Pending,
      };
    }
  } else {
    return {
      valid: false,
      status: Status.Pending,
    };
  }
};

export const isClaimInFirstLevel = (history: any) => {
  if (history && history.length >= 2) return false;
  else return true;
};

export const isClaimReceivedSecondCompensation = (history: any) => {
  if (history && history.length) {
    const lastHistoryFromClient = history.filter((hist: any) =>
      hist.field.toLowerCase().includes('размер на компенсацията')
    );
    if (!lastHistoryFromClient || lastHistoryFromClient.length <= 1) return false;
    return true;
  } else return false;
};

export const isClaimReceivedSecondResponseWithoutCompensation = (
  history: any,
  compensationAmount: any,
  offerCode: any
) => {
  if (history && history.length) {
    const lastHistoryFromClient = history.filter(
      (hist: any) => hist.field.toLowerCase() === 'предложение'
    );
    console.log(lastHistoryFromClient);
    if (
      (compensationAmount === '0.00' || Number(compensationAmount) === 0) &&
      history[0]?.value?.includes('Клиента приема')
    )
      return true;
    if (!lastHistoryFromClient || lastHistoryFromClient.length <= 1) return false;
    if (
      compensationAmount === '0.00' ||
      Number(compensationAmount) === 0 ||
      offerCode === '0108' ||
      offerCode === '0102'
    )
      return true;
    return false;
  } else return false;
};

export const structureLastClientResponseData = (
  history: any,
  paymentOptions?: any,
  compensationAmount?: any
) => {
  // title
  // line1
  // line2
  // line3
  if (paymentOptions && paymentOptions.type) {
    switch (paymentOptions.type) {
      case 'bank':
        return {
          title: 'Заявихте обезщетение',
          line1: 'Подадена заявка за обезщетение по банков път',
          line2: `IBAN: ${paymentOptions.params.IBAN}`,
          line3: `Стойност на обезщетението: ${compensationAmount} лв.`, //     {/* ?.replace('.', ',') */}
        };
      case 'office':
        return {
          title: 'Заявихте обезщетение',
          line1: 'Подадена заявка за обезщетение в офис на Еконт',
          line2: `Избран офис: ${paymentOptions.params.officeName}`,
          line3: `Стойност на обезщетението: ${compensationAmount} лв.`, //      {/* ?.replace('.', ',') */}
        };
      case 'ecoints':
        return {
          title: 'Успешно приехте обезщетение чрез ecoints.',
          // line1: 'in development',
          // line2: 'in development 2',
          // line3: 'in development 3',
        };
      default:
        return {
          title: 'in development',
          line1: 'in development 1',
          line2: 'in development 2',
          line3: 'in development 3',
        };
    }
  }
  if (history && history.length) {
    return {
      title: `${history[0].value}`,
      line1: '',
      line2: '',
      line3:
        Number(compensationAmount) > 0
          ? `Стойност на обезщетението: ${compensationAmount} лв.`//      {/* ?.replace('.', ',') */}
          : '',
    };
  }

  return {
    title: 'History - in development',
    line1: 'in development',
    line2: 'in development 2',
    line3: 'in development 3',
  };
};
