import 'twin.macro';
import AlertPopup from '../components/layout/AlertPopup';
import Wrapper from '../components/layout/Wrapper';

const ErrorExpiredHash = () => {
  return (
    <Wrapper>
      <AlertPopup
        show={true}
        text='Грешка'
        subText='Изтекла е валидността на ключовете.'
        onClick={() => window.location.reload()}
      />
    </Wrapper>
  );
};

export default ErrorExpiredHash;
