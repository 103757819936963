import FilePreview from './FilePreview';
// import { UploadFile } from '../../../types/Form';
import { File } from '../../../types/Form';
import tw from 'twin.macro';

const MAX_FILES_COUNT = 5;

interface Props {
  // files: UploadFile[];
  files: File[];
  onFileDelete?: (index: number) => void; //update to event
}

const FilesList = ({ files, onFileDelete }: Props) => {
  return (
    <div tw='w-full'>
      {/* {onFileDelete && !files && (
        <p tw='font-light text-2.5xs font-body text-grey-light mb-4 mobile:px-2 tablet:px-0'>
          Прикачени файлове
        </p>
      )} */}
      <div css={[tw`flex gap-5 flex-wrap`]}>
        {files.map((data, index, arr) => (
          <div key={index} className='w-32 h-32'>
            <FilePreview
              data={data}
              index={index}
              isLast={arr.length === index + 1}
              onFileDelete={onFileDelete}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesList;
