import useQuery from '../../hooks/use-query-hook';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ClockIcon from '../../components/icons/ClockIcon';
import { ClaimItemType, Status } from '../../types/Data';
import routePaths from '../../route-paths';
import useAuthRedirect from '../../hooks/use-auth-redirect-hook';
import { css } from 'twin.macro';
import {
  getStatusIconClaimItem,
  getStatusTitleClaimItem,
  getResponseTime,
  formatDateInRecommmendedType,
} from '../../utils/claimItemHelpers';
import TooltipInfoIcon from '../icons/TooltipIcon';
import moment from 'moment';
import { isMobile, isTablet } from 'react-device-detect';
import { Fragment } from 'react';
import ArrowRight from '../icons/ArrowRight';

const labels = {
  registerDate: 'Регистрирана на',
  terms: 'Срок на рекламация',
  actionButton: 'Преглед',
  finishedActionButton: 'Детайли',
  receivedOpinionFromEcont: 'Виж становище',
  serviceNumber: ' Номер на услуга',
};

interface Props {
  data: ClaimItemType;
}

const ClaimItem = ({ data }: Props) => {
  const { onRedirect } = useAuthRedirect();
  const queryParameters = useQuery();
  const handleRedirect = () => {
    onRedirect(routePaths.viewClaimByCommonId, { id: data.id || '', imageId: '' }, queryParameters);
  };

  const renderEmotistatus = () => {
    const StatusIcon = getStatusIconClaimItem(data.compensationStatus);

    return (
      <div tw='flex justify-center items-center mr-2'>
        <StatusIcon width='25' height='25' />
      </div>
    );
  };

  const renderLabel = () => {
    const labelsData = data?.label?.split(', ');
    const statusTitle = getStatusTitleClaimItem(data?.compensationStatus);
    const nodeTextFor0041 =
      (data.note?.lastIndexOf('\\n') || 0) > 0
        ? data.note?.slice(data.note?.lastIndexOf('\\n') + 2)
        : labelsData?.map((item, index) => (
            <Fragment key={index}>
              {item}
              {labelsData.length - 1 !== index ? ', ' : ': '}
            </Fragment>
          ));

    return (
      <div
        // css={css`
        //   text-overflow: ellipsis;
        //   overflow: hidden; ;
        // `}
        tw='mobile:(leading-6) text-black-light'
      >
        <div
          tw='font-bold mobile:(text-sm) laptop:(text-sm) break-words'
          style={{ color: statusTitle.color }}
        >
          {statusTitle.text}
        </div>
        <div
          className='line-clamp-2'
          tw='mr-2 text-xs mobile:(mt-1 font-medium text-[13px]) tablet:(mt-1 font-light text-gray-700 text-[13px]) laptop:(mt-1 text-xs font-light text-gray-700)'
        >
          {data?.claimCode === '0041'
            ? nodeTextFor0041
            : labelsData?.map((item, index) => (
                <Fragment key={index}>
                  {item}
                  {labelsData.length - 1 !== index ? ', ' : ': '}
                </Fragment>
              ))}
          {data.shipmentNumbers && <>&nbsp;{data.shipmentNumbers}</>}
        </div>
      </div>
    );
  };

  const renderResponseTime = () => {
    const { text, time, expired, tooltipText, done } = getResponseTime(
      data.compensationStatus,
      data.deadlineDays,
      data.deadlineDays
    );

    return (
      <div tw='flex mobile:(flex-row gap-1 text-[0.85rem] items-start) laptop:(flex-col text-xs) font-light min-w-[150px]'>
        {done && (isMobile || isTablet) ? (
          <span
            className={`${!isMobile && !isTablet && 'text-gray-800'}`}
            tw='mobile:(font-normal text-black-light text-[15px] mb-0 mt-3) laptop:(font-body text-xs mb-1 mt-0)'
          >
            {text} <b tw='ml-1'>{formatDateInRecommmendedType(time)}</b>
          </span>
        ) : done && !isMobile && !isTablet ? (
          <>
            <span
              className={`${!isMobile && !isTablet && 'text-gray-800'}`}
              tw='mobile:(font-normal text-black-light text-[15px] mb-0) laptop:(font-body text-xs mb-1 mt-0)'
            >
              {text}
            </span>
            <span tw='font-bold'>{formatDateInRecommmendedType(time)}</span>
          </>
        ) : (
          <span
            className={`${!isMobile && !isTablet && 'text-gray-800'}`}
            tw='mb-1 mobile:(font-normal text-[12px]) laptop:(font-body text-xs)'
          >
            {text}
          </span>
        )}
        <span
          tw='flex items-start gap-1 font-bold mobile:(flex-row-reverse ml-1) laptop:(flex-row ml-0)'
          style={{ color: expired ? '#e41e1a' : '' }}
        >
          {tooltipText && (
            <div tw='flex items-center h-full'>
              <div tw='mb-1' onClick={(e) => e.stopPropagation()}>
                <TooltipInfoIcon key={Math.random()} tooltipText={tooltipText} />
              </div>
            </div>
          )}
          {!done && time}
        </span>
      </div>
    );
  };

  const renderNumberCol = () => (
    <div tw='text-grey-900 flex items-center text-xs font-bold w-20'>{data.number}</div>
  );

  const renderRegisterDateCol = () => (
    <div tw='flex flex-col text-black-light text-xs w-30'>
      <span tw='font-bold mb-1'>{labels.registerDate}</span>
      <span tw='font-light'>{data.date}</span>
    </div>
  );

  const renderTermseCol = () => (
    <div tw='flex flex-col text-black-light text-xs w-35'>
      <span tw='font-bold mb-1'>{labels.terms}</span>
      <span tw='flex font-light'>
        <ClockIcon className='mr-2' />
        {data.deadlineDays} дни
      </span>
    </div>
  );

  const renderServiceNumberCol = () => {
    if (!data.number)
      return (
        <div tw='mobile:(mt-4) flex flex-col text-black-light text-xs w-full tablet:(min-w-[7.5rem] max-w-[7.5rem] mt-0)'>
          <span tw='mb-1'>{'21'}</span>
        </div>
      );

    const serviceNumberItems = data.number.split(',');

    const joinedServiceNumber = serviceNumberItems.join(', ');

    return (
      <div tw='mobile:(mt-4) flex flex-col text-black-light text-xs w-full tablet:(min-w-[7.5rem] max-w-[7.5rem] mt-0)'>
        <span tw='font-light mb-1'>{labels.serviceNumber}</span>
        <span tw='font-bold break-words'>{joinedServiceNumber}</span>
      </div>
    );
  };

  const renderButton = () => (
    // isMobile || isTablet ? (
    //   <button
    //     tw='flex items-center text-blue-500 text-base text-sm'
    //     onClick={() => handleRedirect()}
    //   >
    //     <FontAwesomeIcon
    //       icon={faChevronRight}
    //       color='#4877CE'
    //       style={{ marginLeft: '0.5rem', fontSize: '1rem', marginTop: '0.2rem' }}
    //     />
    //   </button>
    // ) : (
    <button tw='flex items-center text-blue-500 font-light text-base text-sm'>
      {/* {renderStatusCol()} */}
      <span tw='mobile:hidden laptop:flex'>
        {data.compensationStatus === Status.Done || data.compensationStatus === Status.DoneUnpayed
          ? labels.finishedActionButton
          : data.compensationStatus === Status.Received
          ? labels.receivedOpinionFromEcont
          : labels.actionButton}
      </span>
      {isMobile || isTablet ? (
        <ArrowRight />
      ) : (
        <FontAwesomeIcon
          icon={faChevronRight}
          color='#84a1da'
          fontVariant={'light'}
          style={{ marginLeft: '0.5rem', fontSize: '0.7rem', marginTop: '0.2rem' }}
        />
      )}
    </button>
  );

  const renderTabletView = () => (
    <div
      tw='mobile:(hidden) tablet:(flex items-center justify-between h-full) cursor-pointer'
      onClick={handleRedirect}
    >
      <div tw='laptop:(flex justify-between gap-12 w-9/12) p-3'>
        <div tw='flex items-center'>
          {renderEmotistatus()}
          {renderLabel()}
        </div>
        <div>{renderResponseTime()}</div>
        {/* {renderNumberCol()}
        {renderRegisterDateCol()}
        {renderTermseCol()} */}
        {/* {renderServiceNumberCol()} */}
      </div>
      {renderButton()}
    </div>
  );

  const renderMobileView = () => (
    <div
      tw='mobile:(flex flex-col w-full h-full py-4) tablet:(hidden) cursor-pointer'
      onClick={handleRedirect}
    >
      <div tw='flex justify-between mb-2.5'>
        <div tw='flex w-full items-center'>
          {renderEmotistatus()}
          {renderLabel()}
        </div>
        <div>{renderButton()}</div>
      </div>
      {/* <div
        css={css`
          @media (min-width: 768px) {
            height: 15%;
            padding: 4%;
          }
        `}
        tw='mb-5'
      >
        {renderNumberCol()}
      </div> */}

      <div tw='flex justify-between'>{renderResponseTime()}</div>
      {/* <div tw='flex justify-between '>{renderServiceNumberCol()}</div> */}
    </div>
  );
  return (
    <>
      {data.compensationStatus === Status.Done || data.compensationStatus === Status.DoneUnpayed ? (
        // <div tw='mobile:(h-34.5 pr-5) tablet:(h-14 pr-8) w-full pl-4  text-base border border-grey-800 bg-grey-700 font-light rounded-md'>
        <div tw='mobile:(min-h-[6.525rem] pr-5  h-full) tablet:(min-h-[34px] pr-8) w-full pl-4 text-base border border-grey-800 font-light rounded-md'>
          {renderTabletView()}
          {renderMobileView()}
        </div>
      ) : (
        // <div tw='mobile:(h-34.5 pr-5) tablet:(h-14 pr-8) w-full pl-4  text-base border border-grey-800 bg-grey-700 font-light rounded-md'>
        <div tw='mobile:(min-h-[6.525rem] pr-5  h-full) tablet:(min-h-[34px] pr-8) w-full pl-4 text-base bg-white font-light rounded-md'>
          {renderTabletView()}
          {renderMobileView()}
        </div>
      )}
    </>
  );
};

export default ClaimItem;
