import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import useAuthHook from '../hooks/use-auth-hook';
import useQuery from '../hooks/use-query-hook';
import routePaths from '../route-paths';

import Wrapper from '../components/layout/Wrapper';
import ClaimsView from '../components/features/ClaimsView';
import LoadingSpinner from '../components/common/LoadingSpinner';

import { ClaimItemType, Status } from '../types/Data';

import {
  getClaimByCommonIdExternal,
  getClaimById,
  getClaimByIdExternal,
  getFeedbackByClaimId,
} from '../services/actions';
import tw, { css } from 'twin.macro';
import { hardcodedData } from '../utils/hardcodedClaimsList';
import ButtonWrapper from '../components/Button/ButtonWrapper';
import Button from '../components/Button/Button';
import LeftArrow from '../components/icons/LeftArrow';
import PhoneIcon from '../components/icons/PhoneIcon';
import StepperComponent from '../components/common/StepperComponent';
import { ClaimViewStepperData } from '../constants';
import { claimReasonParser } from '../utils/dataFormatter';
import { Feedback } from '../types/Feedback';
import { getActiveStep } from '../utils/claimItemHelpers';
import {
  getLastHistoryElement_time,
  getLastHistoryElement_time_to_response,
  isClaimAggreedWithDecisionThroughEcoints,
  isClaimReceivedSecondCompensation,
  isClaimReceivedSecondResponseWithoutCompensation,
  isClaimWaitingForEcontResponse,
} from '../utils/helpersFromClaimHistory';
import useAuthRedirect from '../hooks/use-auth-redirect-hook';
import { isMobile, isTablet } from 'react-device-detect';
import HorizontalDotsIcon from '../components/icons/HorizontalDotsIcon';

const ViewClaimByCommonIdPage = () => {
  const queryParameters = useQuery();
  const skipAuth = !!(
    queryParameters.idUser &&
    queryParameters.hashKey &&
    queryParameters.hashKey2
  );
  const { onRedirect } = useAuthRedirect();

  const params: any = useParams();

  const { authLoading, isAuthenticated, isMount } = useAuthHook(
    skipAuth,
    false,
    routePaths.viewClaimByCommonId,
    params
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ClaimItemType | null>(null);
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [error, setError] = useState<string>('');
  const [active, setActive] = useState<number>(1);
  const [isOpenMobileDropdownPhone, setIsOpenMobileDropdownPhone] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (isAuthenticated && isMount) {
      setLoading(true);

      const { page, skipNumbers, ...restParams } = queryParameters;

      let updatedData: ClaimItemType;

      getClaimByCommonIdExternal(params.id || '', restParams)
        .then((result) => {
          setLoading(false);
          if (result) {
            updatedData = {
              ...result,
              deadlineDays:
                result.status && result.status === 'processed'
                  ? getLastHistoryElement_time(result.history)
                  : moment(result.processDeadline).format('DD/MM/YYYY H:mm'),
              //getLastHistoryElement_time_to_response(result.history),
              date: moment(result.date).format('DD/MM/YYYY'),
              label: claimReasonParser([result.claimCode]),
              shipmentNumbers: result.shipmentNumbers?.join(', '),
              compensationStatus: isClaimWaitingForEcontResponse(result.history)
                ? Status.Pending
                : !result.compensationStatus && result.offerCode === '0103'
                ? Status.Done
                : !result.compensationStatus && result.offerCode !== '0103'
                ? Status.DoneUnpayed
                : !result.decisionFileUrl && result.compensationStatus === Status.Received
                ? Status.Pending
                : result.compensationStatus,
              receivedButWithoutDecisionFileUrl:
                !result.decisionFileUrl && result.compensationStatus === Status.Received
                  ? true
                  : false,
            };

            setActive(getActiveStep(updatedData.compensationStatus));
          } else {
            setError('Failed to fetch claim');
          }
        })
        .then(() => {
          getFeedbackByClaimId(params.id, queryParameters).then((res) => {
            if (res && res.claimId) {
              setFeedback(res);
              Object.assign(updatedData, { feedback: res.evaluation });
            } else {
              setFeedback(null);
            }
            setData(updatedData);
            setLoading(false);
          });
        })
        .catch((err) => {
          console.log(err, 'err');
          setLoading(false);
          setError('Failed to fetch claim');
        });
      // setData(hardcodedData[1]);
    }
  }, [isAuthenticated, isMount, params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickLeftArrow = () => {
    onRedirect(routePaths.myClaims, queryParameters, queryParameters);
  };

  const handleCall = (e: any) => {
    e.stopPropagation();
    if (data && data.monitoringPersonPhone) {
      window.open(`tel:${data.monitoringPersonPhone}`);
    } else {
      return;
    }
  };
  const labelsData = data?.label?.split(', ');

  return (
    <Wrapper>
      <div>
        <div
          className={`fixed top-0 left-0 right-0 z-50 bg-white flex justify-center ${
            localStorage.getItem('hideHeader') ? 'mt-0' : 'mt-14'
          }`}
        >
          <div tw='mobile:(px-5) laptop:(px-0 max-w-lg) mt-5 w-full flex flex-col justify-between'>
            <div
              tw='flex cursor-pointer justify-between items-center'
              onClick={handleClickLeftArrow}
            >
              <div className='w-4/5'>
                <span tw='flex items-center justify-start font-light text-xl font-body text-black-100 w-4/5'>
                  <LeftArrow
                    width={isMobile || isTablet ? '32' : '24'}
                    height={isMobile || isTablet ? '32' : '24'}
                    fill='rgb(66 66 66)'
                    viewbox='0 0 19 16'
                  />
                  {data &&
                    labelsData &&
                    labelsData?.map((item: any, index: any) => (
                      <span key={item} tw='truncate'>
                        {item}
                        {labelsData.length - 1 !== index ? ', ' : ''}
                      </span>
                    ))}
                </span>

                <span tw='flex items-center justify-start font-light text-xs font-body text-black-100 w-4/5 ml-[24px]'>
                  Номер на рекламация: {data?.number}
                </span>
              </div>

              {!isMobile && !isTablet ? (
                <div>
                  {data?.monitoringPersonName && data?.monitoringPersonPhone && (
                    <div onClick={handleCall}>
                      <span tw='text-2.5xs text-blue-500 flex justify-center items-center cursor-pointer'>
                        <PhoneIcon width='16' height='16' fill='#4877ce' className='mr-1' />
                        {data?.monitoringPersonName}
                      </span>
                      <span tw='text-2.5xs text-blue-500 flex justify-end cursor-pointer'>
                        {data?.monitoringPersonPhone}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {data?.monitoringPersonName && data?.monitoringPersonPhone && (
                    <div>
                      <div
                        tw='p-2'
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsOpenMobileDropdownPhone((prev) => !prev);
                        }}
                      >
                        <HorizontalDotsIcon width='24' height='24' fill='black' />
                      </div>
                      {isOpenMobileDropdownPhone && (
                        <div
                          className='mobileDropdownPhone'
                          tw='absolute top-12 right-5 bg-white p-4'
                        >
                          <span
                            tw='text-2.5xs text-blue-500 flex cursor-pointer flex items-center'
                            onClick={handleCall}
                          >
                            <PhoneIcon
                              width='14'
                              height='14'
                              fill='rgb(21, 101, 192)'
                              className='mr-1'
                            />
                            {data?.monitoringPersonName}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={`flex gap-3 mt-4 font-body tablet:(ml-5) w-full mb-4`}>
              <StepperComponent
                stepperData={ClaimViewStepperData}
                active={active}
                className='w-full'
              />
            </div>
          </div>
        </div>
        <div className={`${localStorage.getItem('hideHeader') ? 'mt-28' : 'mt-40'}`}>
          {error && <p className='mt-4 text-red-500'>{error}</p>}
          {loading || authLoading ? (
            <LoadingSpinner />
          ) : data ? (
            <ClaimsView
              data={data}
              feedback={feedback}
              active={active}
              parameters={queryParameters}
              refetchData={fetchData}
            />
          ) : null}
          {/* {data && (
            <ClaimsView
              data={data}
              feedback={feedback}
              active={active}
              parameters={queryParameters}
              refetchData={fetchData}
            />
          )} */}
        </div>
      </div>
    </Wrapper>
  );
};

export default ViewClaimByCommonIdPage;
